$(() => {
  if (window.location === window.parent.location) {
    const savedRetailer = sessionStorage.getItem('saved_retailer');
    if (!savedRetailer) {
      $.removeCookie('saved_retailer');
      $.removeCookie('pickup_retailer');
      window.retailer_id = null;
      getLocation();
    } else if (savedRetailer !== Cookies.get('saved_retailer')) {
      Cookies.set('saved_retailer', savedRetailer, { path: '/' });
      window.retailer_id = savedRetailer;
      save_retailer(savedRetailer, 'header');
    }

    if (savedRetailer !== $.cookie('pickup_retailer')) {
      $.removeCookie('pickup_retailer');
    }
  }
});

export function saveRetailer(retailerID) {
  window.retailer_id = retailerID;
  if (!!retailerID) {
    Cookies.set('saved_retailer', retailerID, { path: '/' });
    sessionStorage.setItem('saved_retailer', retailerID);
  } else {
    $.removeCookie('saved_retailer');
    sessionStorage.removeItem('saved_retailer');
  }
}

export function getLocation() {
  if (navigator.geolocation) {
    //console.log("supports geolocation");
    navigator.geolocation.getCurrentPosition(setCoordinates, getCoordinates);
  }
  else {
    //console.log("Geolocation is not supported by this browser.");
    getCoordinates();
  }
}

export function getCoordinates(error) {
  // console.dir(error);
  // console.log("Getting coordinates by IP instead...");
  var refresh = $(".ret-info.load").hasClass('refresh');
  $.ajax({
    url: '/api/retailers/closest?refresh=' + refresh,
    type: 'get',
    success: function(resp) {
      if ( resp && resp.retailer ) {
        saveRetailer(resp.retailer.id)
        update_retailer_on_page(resp);
      }
      else {
        clear_retailer_in_header();
      }
    }
  });
}

export function setCoordinates(position) {
  // console.log("setCoordinates");
  // console.log("Latitude: " + position.coords.latitude + ", Longitude: " + position.coords.longitude);
  var lat = position.coords.latitude;
  var lng = position.coords.longitude;
  var refresh = $(".ret-info.load").hasClass('refresh');
  $.ajax({
    url: '/api/retailers/closest?refresh=' + refresh + '&lat=' + lat + '&lng=' + lng,
    type: 'get',
    success: function(resp) {
      if ( resp && resp.retailer ) {
        saveRetailer(resp.retailer.id);
        update_retailer_on_page(resp);
      }
      else {
        clear_retailer_in_header();
      }
    }
  });
}

$(window).on('load', function() {
  if ( $(".ret-info.load").length > 0 ) {
    getLocation();
  }
});

export function load_retailer_in_header(retailer) {
  $.each($(".ret-info"), function(k,v) {
    var rname = "<span class='name'>" + retailer.retailer_name + "</span>";
    var addr1 = "<span class='addr1'>" + retailer.address1 + "</span>";
    var addr2 = "<span class='addr2'>, " + retailer.address2 + "</span>";
    var city = "<span class='city'>" + retailer.city + ", " + retailer.state + " " + retailer.zip + "</span>";
    var html = "<a href='#' class='retailer-link' onclick='show_retailer_modal(" + retailer.id + ",\"Header\");return false;'>" + rname + addr1;
    if ( retailer.address2 )
      html += addr2
    html += city
    html += '</a><a href="#" onclick="update_retailer(null,null,false,true,\'alt\',\'header\');return false;" class="change">Change <span class="icon-down-big"></span></a>';
    $(v).html(html);
  });
}

export function clear_retailer_in_header() {
  $.each($(".ret-info"), function(k,v) {
    var link = "<a class='find' onclick='update_retailer(null,null,false,true,\"alt\",\"header\");return false;' href='#'>Find a Retailer</a>";
    $(v).removeClass('load').html(link);
  });
  if ( $(".ring-details").length > 0 ) {
    $(".saved-retailer").hide();
    $(".sr-fp.btn").hide();
    $(".sr-fr.btn").hide();
    $(".sr-wr.btn").hide();
    $(".ret-btns").show();
  }
  if ( $(".rings-grid-wrapper").length > 0 ) {
    $(".ret-btns").show();
    $(".saved-retailer").hide();
    $(".sr-fp").hide();
    $(".sr-fr").hide();
    $(".sr-wr").hide();
  }
}

export function show_retailer_image(url) {
  $("#big-image").attr("src",url).show();
}

export function init_retailer_modal() {
  var html = '<div class="fullscreen-overlay" id="retailer-modal"><div class="table modal-wrapper"><div class="table-cell"><div class="modal"><a class="icon-close"></a><div class="content"><div class="top flexbox"><div class="left"><img src="" class="retailer-logo" /><h5 class="retailer-name"></h5><address><span class="retailer-address1"></span><span class="retailer-address2"></span><span class="retailer-city"></span>, <span class="retailer-state"></span> <span class="retailer-zip"></span><span class="retailer-phone"></span></address><div class="map-holder"><img src="" class="retailer-map" alt="Location" /></div></div><div class="right"><h5 class="retailer-about">About Us:</h5><div class="richtext retailer-description"></div><div class="meet flexbox"><div class="meet-text"><h6>Meet Your Local Roots Owner:</h6><div class="richtext meet-bio"></div></div><div class="meet-image"><img src="" class="meet-img" alt="Local Roots Owner" /></div></div></div></div><div class="gallery flexbox"><div class="gallery-image gi-1"><img src="" /></div><div class="gallery-image gi-2"><img src="" /></div><div class="gallery-image gi-3"><img src="" /></div></div><div class="buttons"><a target="_blank" href="#" class="retailer-website-btn btn">Visit Website</a><a href="#" class="retailer-appointment-btn btn">Book Appointment</a></div></div></div></div></div></div>';
  if ( $("#retailer-modal").length == 0 ) {
    $("body").append(html);
    init_overlay_closer();
  }
}

export function init_find_retailer_modal() {
  var html = '<div class="fullscreen-overlay" id="find-retailer-modal"><div class="table modal-wrapper"><div class="table-cell"><div class="modal"><form id="modal-ret-form" class="modal-ret-form" action="#"><input type="hidden" id="mod_ring_id" name="ring_id" value="" /><input type="hidden" id="mod_collection_id" name="collection_id" value="" /><input type="hidden" id="mod_level" name="level" value="" /><input type="hidden" id="mod_free_preview_only" name="free_preview_only" value="" /><input type="text" id="loc-input" name="location" placeholder="City or Zip" /></form><p class="meta">Select an Authorized Retailer</p><div class="sortby"><h6>SORT BY:</h6><div class="btns clearfix"><a href="#" id="level" class="active btn">Partner Status</a><a href="#" id="distance" class="btn">Distance</a></div></div><a id="close-360-modal" class="icon-close"></a><ul id="retailer-list"></ul></div></div></div></div>';
  if ( $("#find-retailer-modal").length != 0 ) {
    $("#find-retailer-modal").remove();
  }

  $("body").append(html);
  init_overlay_closer();
}

export function show_retailer_modal(retailer_id, event_category) {
  event_category = (typeof event_category !== 'undefined' ? event_category : "Retailers");
  if (window.logging) { console.log("ajax call: retailer_info(" + retailer_id + ")"); }
  if ( retailer_id ) {
    init_retailer_modal();
    $.ajax({
      url: '/api/retailers/' + retailer_id,
      type: 'get',
      success: function(resp) {
        var retailer = resp.retailer;
        trigger_event('Viewed Retailer Info Pop Up', event_category, (retailer.retailer_name + " - " + retailer_id), 1);
        $("#retailer-modal .retailer-name").text(retailer.retailer_name ? retailer.retailer_name : "");
        if ( retailer.address1 )
          $("#retailer-modal .retailer-address1").html(retailer.address1).show();
        else
          $("#retailer-modal .retailer-address1").hide();
        if ( retailer.address2 )
          $("#retailer-modal .retailer-address2").html(retailer.address2).show();
        else
          $("#retailer-modal .retailer-address2").hide();
        if ( retailer.phone )
          $("#retailer-modal .retailer-phone").html(retailer.phone).show();
        else
          $("#retailer-modal .retailer-phone").hide();
        $("#retailer-modal .retailer-city").text(retailer.city == null ? '' : retailer.city);
        $("#retailer-modal .retailer-state").text(retailer.state == null ? '' : retailer.state);
        $("#retailer-modal .retailer-zip").text(retailer.zip == null ? '' : retailer.zip);
        $("#retailer-modal .retailer-map").attr('src', resp.map_image_url);
        if ( retailer.brand_ambassador != null && retailer.brand_ambassador != '' )
          $("#retailer-modal .meet.flexbox").show().find(".meet-bio").html(retailer.brand_ambassador);
        else
          $("#retailer-modal .meet.flexbox").hide();
        if ( retailer.description != null && retailer.description != '' ) {
          $("#retailer-modal .retailer-about").show();
          $("#retailer-modal .retailer-description").html(retailer.description).show();
        }
        else {
          $("#retailer-modal .retailer-about").hide();
          $("#retailer-modal .retailer-description").html("").hide();
        }
        if ( resp.website_url != null && resp.website_url != '' ) {
          $("#retailer-modal .retailer-website-btn").attr("href", resp.website_url).show();
          $("#retailer-modal .retailer-website-btn").unbind();
          $("#retailer-modal .retailer-website-btn").on("click", function() {
            trigger_event('Retailer Info - Clicked Visit Website', event_category, (retailer.retailer_name + " - " + retailer_id), 1);
          });
        }
        else
          $("#retailer-modal .retailer-website-btn").hide();
        if ( resp.logo && resp.logo.indexOf('placehold') < 0 )
          $("#retailer-modal .retailer-logo").attr("src",resp.logo).show();
        else
          $("#retailer-modal .retailer-logo").hide();
        if ( resp.image1 && resp.image1.indexOf('placehold') < 0 )
          $("#retailer-modal .gi-1").show().find("img").attr("src",resp.image1);
        else
          $("#retailer-modal .gi-1").hide();
        if ( resp.image2 && resp.image2.indexOf('placehold') < 0 )
          $("#retailer-modal .gi-2").show().find("img").attr("src",resp.image2);
        else
          $("#retailer-modal .gi-2").hide();
        if ( resp.image3 && resp.image3.indexOf('placehold') < 0 )
          $("#retailer-modal .gi-3").show().find("img").attr("src",resp.image3);
        else
          $("#retailer-modal .gi-3").hide();
        if ( resp.ambassador_image && resp.ambassador_image.indexOf('placehold') < 0 )
          $("#retailer-modal .meet-image").show().find(".meet-img").attr("src",resp.ambassador_image);
        else
          $("#retailer-modal .meet-image").hide()
        $("#retailer-modal .retailer-appointment-btn").attr("href", ("/book-appointment?retailer_id=" + retailer.id));
        $("#retailer-modal .retailer-appointment-btn").unbind();
        $("#retailer-modal .retailer-appointment-btn").on("click", function() {
          trigger_event('Retailer Info - Clicked Book Appointment', event_category, (retailer.retailer_name + " - " + retailer_id), 1);
        });
        $("#retailer-modal").fadeIn();
        disableScroll();
      }
    });
  }
}

// $(".show-retailer-modal").click(function(e) {
//   e.preventDefault();
//   // var category = "Retailers";
//   // if ( $(this).closest("header.main").length == 1 )
//   //   category = "Header";
//   show_retailer_modal( $(this).data('retailer'), category );
// });

export function reorder_retailer_results(sortby) {
  var ul = $('ul#retailer-list'), li = ul.children('li');
  li.detach().sort(function(a,b) {
    return $(a).data(sortby) - $(b).data(sortby);
  });
  ul.append(li);
}

export function load_retailer_results_alt(resp, show_select, context, options = {}) {
  if (!options || typeof(options.shouldInit) === 'undefined' || options.shouldInit) {
    init_find_retailer_modal();
  }
  var show_select = (show_select == null || show_select == true) ? true : false;
  var is_pickup = (context == 'pickup');
  var mod = $("#find-retailer-modal");
  mod.addClass("alternate");
  mod.find("#retailer-list").html("");
  if ( is_pickup ) { mod.find("p.meta").text("Select In-Store Pick Up Location"); }
  else { mod.find(".sortby").show(); mod.find("p.meta").text("Select an Authorized Retailer"); }
  mod.find(".sortby .btn").removeClass("active");
  mod.find(`.sortby .btn#${!options || typeof(options.sort) === 'undefined' ? 'level' : options.sort}`).addClass("active");
  $.each(resp.retailers, function(k,v) {
    var select_shown = false;
    var is_platinum_or_gold = ['Platinum', 'Gold'].includes(v.retailer_level_name);
    var li = $("<li />").addClass(v.retailer_level_name).attr("data-level",k).attr("data-distance",v.distance);

    // Levels and Local Roots
    var right = $("<div />").addClass("right");
    var div = $("<div >").addClass("ret-level");

    var img = $("<img />").attr("src", v.badge_url).attr("width","36").attr("height","40");
    div.append(img);

    div.append("<div class='ret-txt'>" + v.retailer_level_text + "</div>");

    right.append(div);

    if ( v.local_roots ) {
      var lr = $("<div />").addClass("ret-level");
      var img2 = $("<img />").attr("src","https://assets.benchmarkrings.com/media/6337318_local_white2x_tiny.png").attr("width","36").attr("height","36").attr("alt","Local Roots");
      lr.append(img2);
      lr.append("<div class='ret-txt'>" + resp.local_roots_text + "</div>");
      right.append(lr);
    }

    li.append(right);

    if ( v.retailer_name ) { li.append("<h6>" + v.retailer_name + "</h6>") };
    if ( v.distance ) { li.append("<p style='margin-bottom:2px;font-size:14px;color:#272727;'><span style='font-family:Arial;font-size:12px;'>" + (Math.round(v.distance * 100) / 100) + "</span> miles</p>") };
    if ( v.address1 ) { li.append("<p>" + v.address1 + "</p>") };
    if ( v.address2 ) { li.append("<p>" + v.address2 + "</p>") };
    if ( v.city && v.state ) { li.append("<p>" + v.city + ", " + v.state + (v.zip ? " " + v.zip : "") + "</p>") };
    var div2 = $("<div />").addClass("r-links");
    if ( v.phone ) { div2.append('<a href="tel:' + v.phone + '" class="icon-phone-2"></a>') };
    if ( v.website ) { div2.append('<a href="' + v.website + '" target="_blank" class="icon-globe"></a>') };
    if ( !is_pickup ) {
      var url = ("/book-appointment?retailer_id=" + v.id);
      var anc = $("<a />").attr("href",url).addClass("btn white-outline").text("Appointment").click(function(e) {
        if (context == 'header')
          trigger_event('Clicked Appointment', 'Header', (v.retailer_name + ' - ' + v.id), 1);
        else if (context == 'in-store-preview')
          trigger_event('Clicked Appointment', 'In Store Preview Request Form', (v.retailer_name + ' - ' + v.id), 1);
        else if (context == 'free-ring')
          trigger_event('Clicked Appointment', 'Free Ring Request Form', (v.retailer_name + ' - ' + v.id), 1);
        else if (context == 'book-appointment')
          trigger_event('Clicked Appointment', 'Book An Appointment', (v.retailer_name + ' - ' + v.id), 1);
      });
      div2.append(anc);
    }
    else if (v.local_roots) {
      var anc = $("<a />").attr("href","#").addClass("small btn").attr("data-id",v.id).text("Pick Up Here").click(function(e) {
        e.preventDefault();
        trigger_event('Selected Retailer', 'Shopping Bag Page', (v.retailer_name + ' - ' + v.id), 1);
        shopify_controller.choose_pickup_retailer(v.id);
      });
      div2.append(anc);
      select_shown = true;
    }
    if ( !select_shown ) {
      if ( context == 'in-store-preview' && v.allow_free_preview ) {
        if ( v.local_roots )
          div2.append("<a onclick='save_and_redirect(" + v.id + ",\"/in-store-preview?retailer_id=" + v.id + "&ring_id=" + resp.ring_id + "\",\"" + context + "\");return false;' class='small btn'>Select</a>");
        else
          div2.append("<a onclick='just_redirect(\"/in-store-preview?retailer_id=" + v.id + "&ring_id=" + resp.ring_id + "\");return false;' class='small btn'>Select</a>");
      }
      else if ( context == 'free-ring' && v.allow_free_preview ) {
        if ( v.local_roots )
          div2.append("<a onclick='save_and_redirect(" + v.id + ",\"/free-ring?retailer_id=" + v.id + "&ring_id=" + resp.ring_id + "\",\"" + context + "\");return false;' class='small btn'>Select</a>");
        else
          div2.append("<a onclick='just_redirect(\"/free-ring?retailer_id=" + v.id + "&ring_id=" + resp.ring_id + "\");return false;' class='small btn'>Select</a>");
      }
      else if ( context == 'book-appointment' ) {
        if ( v.local_roots )
          div2.append("<a onclick='save_and_redirect(" + v.id + ",\"/book-appointment?retailer_id=" + v.id + "\",\"" + context + "\");return false;' class='small btn'>Select</a>");
        else
          div2.append("<a onclick='just_redirect(\"/book-appointment?retailer_id=" + v.id + "\");return false;' class='small btn'>Select</a>");
      }
      else if (is_platinum_or_gold) {
        div2.append("<a href='#' onclick='save_retailer(" + v.id + ",\"" + context + "\");return false;' class='save-retailer small btn' data-id='" + v.id + "'>Select</a>");
      }
    }
    li.append(div2);
    if ( v.collections && v.collections.length > 0 && !is_pickup ) {
      var p = $("<p />").addClass('col').html("<span>Collections:</span>");
      $.each(v.collections, function(k2,v2) {
        p.append(v2);
      });
      li.append(p);
    }
    mod.find("#retailer-list").append(li);
  });
  $("#modal-ret-form #mod_ring_id").val(resp.ring_id);
  $("#modal-ret-form #mod_collection_id").val(resp.collection_id);
  $("#modal-ret-form #mod_level").val(resp.level);
  $("#modal-ret-form #mod_free_preview_only").val(resp.free_preview_only);
  mod.find("form").submit(function(e) {
    if (window.logging) { console.log("modal form submitted"); }
    e.preventDefault();
  });
  $.each( mod.find("#loc-input"), function(j,k) {
    var input = $(k).get(0);
    if ( !$(k).hasClass('gplace') ) {
      $(k).addClass('gplace');
      var options = { types: ['(regions)'], componentRestrictions: { country: ['US','CA'] } };
      window.autocomplete = new google.maps.places.Autocomplete(input, options);
      google.maps.event.addListener(autocomplete, 'place_changed', function() {
        if (window.logging) { console.log("google place_changed"); }
        submit_find_retailer_form("modal-ret-form",true,context);
      });
    }
  });
  if ( mod.is(":hidden") ) {
    mod.find("#loc-input").val("");
    mod.fadeIn();
    disableScroll();
  }

  if (!options || typeof(options.shouldInit) === 'undefined' || options.shouldInit) {
    $("#find-retailer-modal .sortby .btn").on('click', async function(e) {
      e.preventDefault();
      $("#find-retailer-modal .sortby .btn").removeClass("active");
      $(this).addClass("active");

      const $retailerList = $('#find-retailer-modal #retailer-list');
      injectLoadingIndicator($retailerList);

      const url = new URL(`https://${window.location.host}/api/retailers`);
      Object.keys(options).forEach(key => {
        url.searchParams.append(key, options[key] || '');
      });
      url.searchParams.append('sort', this.id);
      if (window.ringCollectionID && context === 'header') {
        url.searchParams.append('prioritize_by_collection_id', window.ringCollectionID);
      }

      const response = await fetch(`${url.pathname}${url.search}`, { method: 'post', credentials: 'include'});
      const responseData = await response.json();
      load_retailer_results_alt(responseData, show_select, context, { shouldInit: false, sort: this.id });
    });
  }
}

export function load_retailer_results(resp, show_select) {
  init_find_retailer_modal();
  var show_select = (show_select == null || show_select == true) ? true : false;
  var mod = $("#find-retailer-modal");
  mod.removeClass("alternate");
  mod.find("#retailer-list").html("");
  $.each(resp.retailers, function(k,v) {
    var select_shown = false;
    var is_platinum_or_gold = ['Platinum', 'Gold'].includes(v.retailer_level_name);
    var li = $("<li />");

    // Levels and Local Roots
    var right = $("<div />").addClass("right");
    var div = $("<div >").addClass("ret-level");

    //var ls = v.level && v.level != '' ? v.level.toLowerCase() : 'gold';
    var img = $("<img />").attr("src", v.badge_url).attr("width","36").attr("height","40");
    div.append(img);

    div.append("<div class='ret-txt'>" + v.retailer_level_text + "</div>");

    right.append(div);

    if ( v.local_roots ) {
      var lr = $("<div />").addClass("ret-level");
      var img2 = $("<img />").attr("src","https://assets.benchmarkrings.com/media/6337318_local_white2x_tiny.png").attr("width","36").attr("height","36").attr("alt","Local Roots");
      lr.append(img2);
      lr.append("<div class='ret-txt'>" + resp.local_roots_text + "</div>");
      right.append(lr);
    }

    li.append(right);


    if ( v.retailer_name ) { li.append("<h6>" + v.retailer_name + "</h6>") };
    if ( v.distance ) { li.append("<p style='margin-bottom:2px;'><span style='font-family:Arial;font-size:14px;'>" + (Math.round(v.distance * 100) / 100) + "</span> miles</p>") };
    if ( v.address1 ) { li.append("<p>" + v.address1 + "</p>") };
    if ( v.address2 ) { li.append("<p>" + v.address2 + "</p>") };
    if ( v.city && v.state ) { li.append("<p>" + v.city + ", " + v.state + (v.zip ? " " + v.zip : "") + "</p>") };
    if ( v.collections && v.collections.length > 0 ) {
      var p = $("<p />").addClass('col').text("Authorized collections: ");
      $.each(v.collections, function(k2,v2) {
        p.append(v2);
      });
      li.append(p);
    }
    if ( ($(".ring-details").length > 0 && v.allow_free_preview && resp.ring_id && resp.ring_free ) || ( $(".appointment-page").length > 0 && v.allow_free_preview && resp.ring_id && resp.ring_free )) {
      if ( !v.local_roots || !is_platinum_or_gold ) {
        li.append("<a onclick='just_redirect(\"/free-ring?retailer_id=" + v.id + "&ring_id=" + resp.ring_id + "\");return false;' class='small btn pink'>Free Ring</a>");
      }
      else {
        li.append("<a onclick='save_and_redirect(" + v.id + ",\"/free-ring?retailer_id=" + v.id + "&ring_id=" + resp.ring_id + "\",'modal');return false;' class='small btn pink'>Free Ring</a>");
      }
    }
    else {
      if (show_select && !select_shown && is_platinum_or_gold) {
        li.append("<a href='#' onclick='save_retailer(" + v.id + ",\"" + context + "\");return false;' class='save-retailer small btn' data-id='" + v.id + "'>Select</a>");
        select_shown = true;
      }
      if ( v.allow_free_preview ) {
        if (resp.ring_id && resp.ring_free) {
          if ( !v.local_roots || !is_platinum_or_gold ) {
            li.append("<a onclick='just_redirect(\"/free-ring?retailer_id=" + v.id + "&ring_id=" + resp.ring_id + "\");return false;' class='small pink btn'>Free Ring</a>");
          }
          else {
            li.append("<a onclick='save_and_redirect(" + v.id + ",\"/free-ring?retailer_id=" + v.id + "&ring_id=" + resp.ring_id + "\",'modal');return false;' class='small pink btn'>Free Ring</a>");
          }
        }
        else if (!show_select && !select_shown && is_platinum_or_gold) { li.append("<a href='#' onclick='save_retailer(" + v.id + ",\"" + context + "\");return false;' class='save-retailer small btn' data-id='" + v.id + "'>Select</a>"); select_shown = true; }
      }
    }
    //console.dir(v);
    if ( ($(".ring-details").length > 0 && v.allow_free_preview && resp.ring_id && resp.ring_previewable ) || ( $(".appointment-page").length > 0 && v.allow_free_preview && resp.ring_id && resp.ring_previewable )) {
      //console.dir(v.retailer_name + ", local roots: " + v.local_roots);
      if ( !v.local_roots || !is_platinum_or_gold ) {
        li.append("<a onclick='just_redirect(\"/in-store-preview?retailer_id=" + v.id + "&ring_id=" + resp.ring_id + "\");return false;' class='small btn'>In-Store Preview</a>");
      }
      else {
        li.append("<a onclick='save_and_redirect(" + v.id + ",\"/in-store-preview?retailer_id=" + v.id + "&ring_id=" + resp.ring_id + "\",'modal');return false;' class='small btn'>In-Store Preview</a>");
      }
    }
    else {
      if (show_select && !select_shown && is_platinum_or_gold) {
        li.append("<a href='#' onclick='save_retailer(" + v.id + ",\"" + context + "\");return false;' class='save-retailer small btn' data-id='" + v.id + "'>Select</a>");
        select_shown = true;
      }
      if ( v.allow_free_preview ) {
        if (resp.ring_id && resp.ring_previewable) {
          if ( !v.local_roots || !is_platinum_or_gold ) {
            li.append("<a onclick='just_redirect(\"/in-store-preview?retailer_id=" + v.id + "&ring_id=" + resp.ring_id + "\");return false;' class='small btn'>In-Store Preview</a>");
          }
          else {
            li.append("<a onclick='save_and_redirect(" + v.id + ",\"/in-store-preview?retailer_id=" + v.id + "&ring_id=" + resp.ring_id + "\",'modal');return false;' class='small btn'>In-Store Preview</a>");
          }
        }
        else if (!show_select && !select_shown && is_platinum_or_gold) { li.append("<a href='#' onclick='save_retailer(" + v.id + ",\"" + context + "\");return false;' class='save-retailer small btn' data-id='" + v.id + "'>Select</a>"); select_shown = true; }
      }
    }
    mod.find("#retailer-list").append(li);
  });
  $("#modal-ret-form #mod_ring_id").val(resp.ring_id);
  $("#modal-ret-form #mod_collection_id").val(resp.collection_id);
  $("#modal-ret-form #mod_level").val(resp.level);
  $("#modal-ret-form #mod_free_preview_only").val(resp.free_preview_only);
  mod.find("form").submit(function(e) {
    if (window.logging) { console.log("modal form submitted"); }
    e.preventDefault();
  });
  $.each( mod.find("#loc-input"), function(j,k) {
    var input = $(k).get(0);
    if ( !$(k).hasClass('gplace') ) {
      $(k).addClass('gplace');
      var options = { types: ['(regions)'], componentRestrictions: { country: ['US','CA'] } };
      window.autocomplete = new google.maps.places.Autocomplete(input, options);
      google.maps.event.addListener(autocomplete, 'place_changed', function() {
        if (window.logging) { console.log("google place_changed"); }
        submit_find_retailer_form("modal-ret-form",true,'modal');
      });
    }
  });
  if ( mod.is(":hidden") ) {
    mod.find("#loc-input").val("");
    mod.fadeIn();
    disableScroll();
  }
}

export function update_retailer(ring_id, collection_id, free_preview_only, show_select, style, context) {
  var show_select = (show_select == null || show_select == true) ? true : false;
  context = context && context != '' ? context : 'none';

  if (window.logging) { console.log("ajax call: update_retailer()"); }

  if (context == 'header')
    trigger_event('Clicked Change Retailer', 'Header', '', 1);
  else if (context == 'in-store-preview')
    trigger_event('Clicked Change Retailer', 'In Store Preview Request Form', '', 1);
  else if (context == 'free-ring')
    trigger_event('Clicked Change Retailer', 'Free Ring Request Form', '', 1);
  else if (context == 'book-appointment')
    trigger_event('Clicked Change Retailer', 'Book An Appointment', '', 1);

    const dataToSubmit = { closest : 'true', ring_id : ring_id, collection_id : collection_id, free_preview_only : free_preview_only };
    if (window.ringCollectionID && context === 'header') {
      dataToSubmit['prioritize_by_collection_id'] = window.ringCollectionID;
    }
  $.ajax({
    url: '/api/retailers',
    data: dataToSubmit,
    type: 'post',
    success: function (resp) {
      if ( resp && resp.retailers && resp.retailers.length > 0 ) {
        $(".ring-overlay-modal").fadeOut();
        enableScroll();
        if (style == 'main') {
          load_retailer_results(resp, show_select);
        } else {
          load_retailer_results_alt(resp, show_select, context, { closest : 'true', ring_id : ring_id, collection_id : collection_id, free_preview_only : free_preview_only });
        }
      } else {
        alert('Sorry, no matching retailers were found within 100 miles.');
      }
    }
  });
}

export function submit_find_retailer_form(form_id, show_select, context) {
  var style = $("#find-retailer-modal").hasClass("alternate") ? "alt" : "main";
  var show_select = (show_select == null || show_select == true) ? true : false;
  var is_pickup = (context == 'pickup');
  var form = $("#" + form_id);
  $("#ret-message").html("");
  if (window.logging) { console.log("ajax call: find_retailers()"); }

  $.ajax({
    url: '/api/retailers',
    data: form.serialize() + (is_pickup ? "&pickup=true" : ""),
    type: 'post',
    success: function(resp) {
      if ( resp && resp.retailers && resp.retailers.length > 0 )
        style == 'main' ? load_retailer_results(resp, show_select) : load_retailer_results_alt(resp, show_select, context, Object.assign({},form.serializeArray().reduce((obj, pair) => {obj[pair.name] = pair.value; return obj}, {}), { is_pickup }));
      else
        form.find("#ret-message").html("<p class='note error'>Sorry, no retailers were found near that location.</p>");
      if ( context == 'header' )
        trigger_event('Searched For Retailer', 'Header', resp.location, 1);
      else if ( context == 'in-store-preview' )
        trigger_event('Searched For Retailer', 'In Store Preview Request Form', resp.location, 1);
      else if ( context == 'free-ring' )
        trigger_event('Searched For Retailer', 'Free Ring Request Form', resp.location, 1);
      else if ( context == 'book-appointment' )
        trigger_event('Searched For Retailer', 'Book An Appointment', resp.location, 1);
      else if ( context == 'pickup' )
        trigger_event('Searched For Retailer', 'Shopping Bag Page', resp.location, 1);

      trigger_standard_event(StandardEvent.FIND_LOCATION);
    }
  });
}

$(".ring-retailer-form").submit(function(e) {
  e.preventDefault();
  submit_find_retailer_form( $(this).attr("id"), true, 'ring-details');
});

export function gtag_retailer(rid, context, redirect_url) {
  if (rid) {
    $.ajax({
      url: '/api/retailers/' + rid,
      type: 'get',
      success: function(resp) {
        if ( resp && resp.retailer && resp.retailer.retailer_name ) {
          if ( context == 'header' )
            trigger_event('Set Local Roots Retailer', 'Header', (resp.retailer.retailer_name + " - " + rid), 1);
          else if ( context == 'in-store-preview' )
            trigger_event('Selected Retailer', 'In Store Preview Request Form', (resp.retailer.retailer_name + " - " + rid), 1);
          else if ( context == 'free-ring' )
            trigger_event('Selected Retailer', 'Free Ring Request Form', (resp.retailer.retailer_name + " - " + rid), 1);
          else if ( context == 'book-appointment' )
            trigger_event('Selected Retailer', 'Book An Appointment', (resp.retailer.retailer_name + " - " + rid), 1);
        }
        if ( redirect_url ) {
          //console.log("redirect to " + redirect_url);
          window.location = redirect_url;
        }
      }
    });
  }
}

export function show_ret_search_form() {
  $("#retailer-form-wrapper").slideDown();
  $(".retailer-map-wrap > h2 a.chng").hide();
  $("html, body").animate({ scrollTop: $("#retailer-form-wrapper").offset().top - 150 }, 1000);
}

export function save_and_redirect(rid, url, context) {
  saveRetailer(rid);
  gtag_retailer(rid, context, url);
}

export function just_redirect(url) {
  window.location = url;
}

export function save_retailer(rid, context) {
  if (rid) {
    saveRetailer(rid);
    $("#find-retailer-modal").fadeOut();
    enableScroll();
    if ( $(".appointment-page").length > 0 ) {
      var ring_id = $(".appointment-page").data('ringid');
      var is_free = $(".appointment-page h1.lined").first().text() == 'Free Ring Request Form';
      var is_free_eligible = $(".appointment-page").hasClass("fre");
      if ( window.location.href.indexOf('book-appointment') >= 0 ) {
        var url = "/book-appointment?retailer_id=" + rid;
        gtag_retailer(rid, context, url);
      }
      else
        if (is_free_eligible) {
          var url = '/free-ring-request';
          gtag_retailer(rid, context, url);
        }
        else if (is_free) {
          var url = "/free-ring?retailer_id=" + rid + "&ring_id=" + ring_id;
          gtag_retailer(rid, context, url);
        }
        else {
          var url = "/in-store-preview?retailer_id=" + rid + "&ring_id=" + ring_id;
          gtag_retailer(rid, context, url);
        }
    }
    else {
      gtag_retailer(rid, context, false);
      $.ajax({
        url: '/api/retailers/' + rid,
        type: 'get',
        success: function(resp) {
          update_retailer_on_page(resp);
        }
      });
    }
  }
}

export function update_retailer_on_page(resp) {
  window.retailer_id = resp.retailer.id;
  // console.log("updating retailer on page");
  // console.dir(resp);
  if ( resp.retailer ) {
    // console.log("selected retailer is local roots, choosing pickup retailer " + resp.retailer.id);
    shopify_controller.choose_pickup_retailer(resp.retailer.id);
    load_retailer_in_header(resp.retailer);
  }
  var event_context = "Quick View";
  var ring_name = "";
  var rid = resp.retailer.id;
  var html = "";
  if (resp.retailer.retailer_name) { html += resp.retailer.retailer_name + "<br />"; }
  if (resp.retailer.address1) { html += resp.retailer.address1 + "<br />"; }
  if (resp.retailer.address2) { html += resp.retailer.address2 + "<br />"; }
  if (resp.retailer.city && resp.retailer.state)
    html += resp.retailer.city + ", " + resp.retailer.state + " " + resp.retailer.zip
  if ( $(".appointment-page.fre").length > 0 ) { // Free Ring Request page
    location.reload();
    return;
  }
  if ( $(".ring-modal").length > 0 ) {
    event_context = "Quick View";
    ring_name = $(".ring-modal .ring-details").data("style");
  }
  else if ( $(".ring-details").length > 0 ) {
    event_context = "Product Detail Page";
    ring_name = $(".ring-details").first().data("style");
  }

  if ( $(".ring-details").length > 0 ) {
    var ring_id = $(".ring-details").attr("id").replace("ring_","");
    var collection_id = $(".ring-details").data("collection");
    $(".sr-fp.btn").attr("href","#").attr('onclick','trigger_event("Clicked In Store Preview","' + event_context + '","' + ring_name + '",1);update_retailer(' + ring_id + ',' + collection_id + ',true,false,\"alt\",\"in-store-preview\");return false;').show();
    $(".sr-fr.btn").attr("href","#").attr('onclick','trigger_event("Clicked Free Ring","' + event_context + '","' + ring_name + '",1);update_retailer(' + ring_id + ',' + collection_id + ',true,false,\"alt\",\"free-ring\");return false;').show();
    $(".sr-wr.btn").show();
    $(".saved-retailer").hide();
    $(".saved-retailer").find("address").html(html);
    $(".saved-retailer").find("a.map-link").attr("href",resp.directions_url);
    $(".saved-retailer a img").attr("src",resp.map_image_url)
    $(".ret-btns").hide();
  }
  $.each(resp.all_collections, function(k,v) {
    if ( $(".ring-details").length > 0 ) {
      if ( v == $(".ring-details").data("collection") ) {
        var ring_id = $(".ring-details").attr("id").replace("ring_","");
        $(".sr-fr.btn").attr("href","/free-ring?ring_id=" + ring_id + "&retailer_id=" + rid).attr('onclick','trigger_event("Clicked Free Ring","' + event_context + '","' + ring_name + '",1);').show();
        $(".sr-fp.btn").attr("href","/in-store-preview?ring_id=" + ring_id + "&retailer_id=" + rid).attr('onclick','trigger_event("Clicked In Store Preview","' + event_context + '","' + ring_name + '",1);').show();
        $(".sr-wr.btn").show();
        $(".saved-retailer").show();
      }
    }
  });
}

export function change_retailer() {
  saveRetailer(null);
  window.retailer_id = null;
  clear_retailer_in_header();
  $(".ret-btns").show();
  $(".saved-retailer").hide();
  $(".sr-fp").hide();
  $(".sr-fr").hide();
  $(".sr-wr").hide();
}

export function retailer_free_preview() {
  $(".ret-btns").hide();
  $(".ring-retailer-form #ret-col").remove();
  var input = $("<input />").attr("id","ret-col").attr("type","hidden").attr("name","free_preview_only").attr("value","true");
  $(".ring-retailer-form").find("label").text("Find an In-Store Preview Retailer:");
  $(".ring-retailer-form").append(input).show();
  $.each( $(".ring-retailer-form input#location"), function(j,k) {
    var input = $(k).get(0);
    var options = { types: ['(regions)'], componentRestrictions: { country: ['US','CA'] } };
    window.autocomplete = new google.maps.places.Autocomplete(input, options);
    google.maps.event.addListener(autocomplete, 'place_changed', function() {
      $(input).closest("form").trigger('submit');
    });
  })
}

export function retailer_find() {
  $(".ret-btns").hide();
  $(".ring-retailer-form #ret-col").remove();
  var input = $("<input />").attr("id","ret-col").attr("type","hidden").attr("name","free_preview_only").attr("value","false");
  $(".ring-retailer-form").find("label").text("Find a Retailer:");
  $(".ring-retailer-form").append(input).show();
  $.each( $(".ring-retailer-form input#location"), function(j,k) {
    var input = $(k).get(0);
    var options = { types: ['(regions)'], componentRestrictions: { country: ['US','CA'] } };
    window.autocomplete = new google.maps.places.Autocomplete(input, options);
    google.maps.event.addListener(autocomplete, 'place_changed', function() {
      $(input).closest("form").trigger('submit');
    });
  })
}