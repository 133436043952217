export const ShopifyController = function(params) { this.init(params); };

ShopifyController.ItemType = {
  RING: 0,
  ENGRAVING: 1,
  FREE_RING: 2,
  CUSTOMIZATION: 3,
};

ShopifyController.prototype = {
  access_token: false,
  checkout: false,
  checkout_id: false,
  client: false,
  domain: false,
  product: [],
  product_id: false,
  variant_id: false,
  cart_els: [],
  header_cart: false,
  eng_block: false,
  eng_script: false,
  full_style_number: false,
  sizer_id: false,
  sizer_long_id: false,
  free_ring_ids: [],
  free_ring_long_ids: [],
  sizer_added: false,
  ring_data: false,
  size_data: false,
  pickup_selected: false,
  pickup_retailer_id: false,
  bag_hover_start: false,
  pickup_info_hover_start: false,
  local_roots_retailer_id: false,
  pending_size_ring_added: false,
  checkout_url: false,
  confirm_text: "<p>All returns will incur a $45 restocking fee, so make sure you are ordering the right size! If you're not sure, order one of our free ring sizing kits before placing your order.</p>",
  free_ring_cta_text: 'Eligible for a free ring →',
  cart: null,
  lineItems: [],

  init: async function(params) {
    var that = this;
    that.pickup_retailer_id = Cookies.get('pickup_retailer');
    await this.initCart();
    if (that.pickup_retailer_id) {
      that.pickup_selected = true
      $(document).ready(() => {
        var ivl = setInterval(() => {
          var $pickupBox = $('#in-store-pickup-checkbox');
          const cartElement = document.querySelector('.shopify-cart-element');
          if (($pickupBox.length > 0 || (window.pickupRetailers ?? []).length === 0) && cartElement && !Array.from(cartElement.classList).includes('loading')) {
            clearInterval(ivl);
            if ((window.pickupRetailers ?? []).length > 0) {
              that.add_in_store_pickup(that.pickup_retailer_id);
              trigger_event('Checked In Store Pick Up box', 'Shopping Bag Page','', 1);
              $pickupBox.parent().addClass('selected');
              $pickupBox[0].checked = true;
              var pickupInfo = JSON.parse(Cookies.get('pickup_info') || '{}');
              Object.keys(pickupInfo).forEach(key => {
                $(`#${key}`)[0].value = pickupInfo[key];
              });
            } else {
              that.remove_in_store_pickup(true);
            }
          }
        }, 500);
      })
    } else {
      that.pickup_retailer_id = false;
    }
    for (var i in params)
      this[i] = params[i];
    if ( Cookies.get('caboose_shopify_checkout_id') )
      this.checkout_id = Cookies.get('caboose_shopify_checkout_id');
    if ( $(".ring-details").length > 0 )
      this.full_style_number = $(".ring-details .right-unit p.in").text();
    this.init_client();
    this.bind_events();
    this.load_ring_data();
    this.sizer_long_id = this.get_long_variant_id(this.sizer_id);
    this.free_ring_long_ids = this.free_ring_ids.map(function(id) { return that.get_long_variant_id(id); });
  },

  bind_events: function() {
    var that = this;

    that.local_roots_retailer_id = window.retailer_id;

    $(document).on('click', '.shopify-add-to-cart', function(e) {
      e.preventDefault();
      var el = $(this);
      if ( !el.hasClass("disabled") ) {
        el.addClass("disabled");
        var ring_id = el.data("id");
        if ( that.is_ring_made_to_order(ring_id) )
          that
            .add_to_cart(el, true)
            .then(that.update_page_after_cart_update.bind(that));
        else
          that.show_confirmation_modal(el);
      }
    });

    $(document).on('click', '.shopify-update-cart', function(e) {
      e.preventDefault();
      var el = $(this);
      if ( !el.hasClass("disabled") ) {
        el.addClass("disabled");
        that.update_item_in_cart(el);
      }
    });

    $(document).on('mouseenter', '.shopify-show-cart-hover', function(e) {
      e.preventDefault();
      that.bag_hover_start = new Date().getTime();
      that.show_cart();
    });

    $(document).on('mouseleave', '.shopify-show-cart-hover', function(e) {
      e.preventDefault();
      var current = new Date().getTime();
      if ( that.bag_hover_start ) { trigger_event_with_times('Viewed Bag Preview', 'Header', that.bag_hover_start, current); }
      that.hide_cart();
    });

    $(document).on('click', 'header .shopify-show-cart-click', function(e) {
      e.preventDefault();
      that.bag_hover_start = new Date().getTime();
      that.toggle_cart();
    });

    $(document).on('click', '.shopify-close-cart', function(e) {
      e.preventDefault();
      if ( that.bag_hover_start ) { trigger_event_with_times('Viewed Bag Preview', 'Header', that.bag_hover_start, current); }
      that.hide_cart();
    });

    $(".pickup-wrap .show-info-popup").mouseenter( function() {
      that.pickup_info_hover_start = new Date().getTime();
    });

    $(".pickup-wrap .show-info-popup").mouseleave( function() {
      var current = new Date().getTime();
      if ( that.pickup_info_hover_start ) { trigger_event_with_times('Viewed In Store Pick Up Info', 'Shopping Bag Page', that.pickup_info_hover_start, current); }
    });

    $(document).on('change', '#pickup-person-form input', function (e) {
      var pickupInfo = {
        'pickup-person': $('#pickup-person')[0].value,
        'pickup-phone': $('#pickup-phone')[0].value,
        'pickup-email': $('#pickup-email')[0].value
      };

      Cookies.set('pickup_info', JSON.stringify(pickupInfo));
    })

    $(document).on('click', '.shopify-checkout-btn', async function(e) {
      e.preventDefault();
      var btn = $(this);
      var mess = $("#bag-cart-message");
      mess.empty();
      if ( !btn.hasClass('disabled') ) {
        btn.addClass("disabled");
        if ( that.pickup_selected && !that.pickup_retailer_id ) {
          that.choose_pickup_retailer( that.local_roots_retailer_id );
          btn.removeClass("disabled");
        }
        else if ( that.pickup_selected && $("#pickup-person").val() == "" ) {
          mess.html("<p class='note error'>Please add a Pick Up Name above.</p>");
          btn.removeClass("disabled");
        }
        else if ( that.pickup_selected && $("#pickup-phone").val() == "" ) {
          mess.html("<p class='note error'>Please add a Pick Up Phone Number above.</p>");
          btn.removeClass("disabled");
        }
        else if ( that.pickup_selected &&  ( $("#pickup-email").val() == "" || $("#pickup-email").val().indexOf("@") < 0 ) ) {
          mess.html("<p class='note error'>Please add a Pick Up Email Address above.</p>");
          btn.removeClass("disabled");
        }
        else {
          btn.text("Opening Checkout...");
          // that.set_checkout_custom_attributes($("#pickup-person").val(), $("#pickup-phone").val(), $("#pickup-email").val());

          try {
            await that.setCheckoutAttributes();

            const checkout = await window.cartProvider.getCheckout();

            if (checkout) {
              trigger_event('Clicked Checkout', 'Shopping Bag Page', '', 1);
              trigger_standard_event(StandardEvent.INITIATE_CHECKOUT);

              window.location.href = checkout.checkoutURL;
            }
          } catch(e) {
            console.error(e);
            alert('Checkout failed. Please try again.');
            window.location.reload();
          }
        }
      }
    });

    var inp = $("#in-store-pickup-checkbox").first();
    if ( inp.length > 0 && inp.closest(".custom-in.cb").length == 0 )
      customCheckbox(inp);

    $(document).on('click', '#change-pickup-retailer', function(e) {
      e.preventDefault();
      trigger_event('Clicked Change Retailer', 'Shopping Bag Page','', 1);
      that.change_pickup_retailer();
    });

    $(document).on('change', '#in-store-pickup-checkbox', function() {
      if ( $(this).is(":checked") ) {
        that.add_in_store_pickup();
        trigger_event('Checked In Store Pick Up box', 'Shopping Bag Page','', 1);
      }
      else {
        that.remove_in_store_pickup();
      }
    });

  },

  load_ring_data: function() {
    var that = this;
    $.ajax({
      url: '/api/ring-cart-data',
      type: 'get',
      success: function(resp) {
        that.ring_data = resp.rings;
        that.size_data = resp.sizes;
      }
    });
  },

  init_client: function() {
    var that = this;
    that.client = ShopifyBuy.buildClient({
      domain: that.domain,
      storefrontAccessToken: that.access_token
    });
    that.init_carts();
    this.after_init();
  },

  setCheckoutAttributes: async function() {
    this.cart = await window.cartProvider.get();
    if (this.pickup_selected) {
      window.pickupAttributes = this.buildInStorePickupAttributes({
        person: $("#pickup-person").val(),
        phone: $("#pickup-phone").val(),
        email: $("#pickup-email").val()
      });
      this.cart.attributes = pickupAttributes.concat(this.cart.attributes || []);
    } else if (this.cart.attributes) {
      this.cart.attributes = this.getNonInStorePickupCartAttributes(this.cart.attributes);
    }

    var gaClientId = this.getGAValue('clientId');
    if (gaClientId) {
      (this.cart.attributes || []).push({ key: "_ga_client_id", value: gaClientId });
    }

    await window.cartProvider.update({
      has_in_store_pickup: this.pickup_selected,
      attributes: this.cart.attributes
    })
  },

  buildInStorePickupAttributes: function({ person, phone, email }) {
    if (this.pickup_retailer_id) {
      const attributes = [
        {
          key: "pickup_name",
          value: person
        },
        {
          key: "pickup_phone",
          value: phone
        },
        {
          key: "pickup_email",
          value: email
        },
        {
          key: "pickup_retailer_id",
          value: this.pickup_retailer_id.toString()
        }
      ];

      return attributes
    } else {
      throw new Error('No pickup retailer id');
    }
  },

  getNonInStorePickupCartAttributes: async function(existingAttributes) {
    const keysToRemove = ['pickup_name', 'pickup_phone', 'pickup_email', 'pickup_retailer_id'];
    const attributes = existingAttributes.filter(attribute => !keysToRemove.includes(attribute.key));
    return attributes;
  },

  getGAValue: function(key) {
    if (!window.ga) {
      return '';
    }

    var trackers = window.ga.getAll();
    if (trackers.length === 0) {
      return '';
    }

    return trackers[0].get(key);
  },

  updateQuantity: function(btnClicked, qtyChange) {
    var controller = this;
    var $btnClicked = $(btnClicked);
    if ($btnClicked.hasClass('disabled')) {
      return;
    }

    var $container = $btnClicked.closest('.shopify-quantity-selector');
    var $input = $container.find('.shopify-quantity-input');
    var $qtyDisplay = $container.find('.quantity-span');

    var qty = parseInt($input.val());
    var newQty = Math.max(1, qty + qtyChange);

    $input.val(newQty);
    $qtyDisplay.text(newQty);

    $container.find('.btn.dec').toggleClass('disabled', newQty === 1);

    var inBag = $btnClicked.closest('.shopify-cart-element').length > 0;
    if (inBag) {
      controller.carts_loading(true);

      var $cartLine = $container.closest('.shopify-line-item');
      var lineItem = controller.lineItems.filter(function(line) {
        return line.id === $cartLine.data('shopify-line-item-id');
      }).shift();

      if (!lineItem) {
        return;
      }

      if (newQty === 0) {
        $container.closest('.shopify-line-item').remove();
        controller.remove_line_item(lineItem);
      } else {
        controller.update_line_item_quantity(lineItem, newQty);
      }
    }
  },

  change_pickup_retailer: function() {
    var that = this;
    $.ajax({
      url: '/api/retailers',
      data: { 'closest' : 'true', 'ring_id' : null, 'collection_id' : null, 'free_preview_only' : false, 'pickup': true, sort: 'distance' },
      type: 'post',
      success: function(resp) {
        if ( resp && resp.retailers && resp.retailers.length > 0 ) {
          load_retailer_results_alt(resp, true, 'pickup', { sort: 'distance', closest : 'true', ring_id : null, collection_id : null, free_preview_only : false, pickup: true });
        } else {
          that.remove_in_store_pickup();
          $('#in-store-pickup-checkbox')[0].checked = false;
          $('#in-store-pickup-checkbox').parent().removeClass('selected');
          alert('Sorry, no valid retailers were found within 100 miles.');
        }
      }
    });
  },

  choose_pickup_retailer: function(retailer_id) {
    var that = this;
    that.pickup_retailer_id = retailer_id;
    if ( retailer_id ) {
      that.local_roots_retailer_id = retailer_id;

      if (that.pickup_selected) {
        Cookies.set('pickup_retailer', retailer_id);
      }
    }
    var rid = that.pickup_selected ? (that.pickup_retailer_id ? that.pickup_retailer_id : false) : false;

    // Get retailer info from API and display on the page
    if ( rid ) {
      $("#find-retailer-modal").fadeOut();
      $.ajax({
        url: '/api/retailers/' + rid,
        type: 'get',
        success: function(resp) {
          that.update_retailer_info(resp);
        }
      });
    }

    // Ask user to select a retailer
    else if ( that.pickup_selected ) {
      that.change_pickup_retailer();
    }
  },

  update_retailer_info: function(resp) {
    var that = this;
    if ( resp.retailer ) {
      var html = "<address>";
      if (resp.retailer.retailer_name) { html += "<span class='name'>" + resp.retailer.retailer_name + "</span>"; }
      if (resp.retailer_distance) { html += "<span class='distance'>" + resp.retailer_distance + "</span>"; }
      if (resp.retailer.address1) { html += "<span class='addr1'>" + resp.retailer.address1 + "</span>"; }
      if (resp.retailer.address2) { html += "<span class='addr2'>" + resp.retailer.address2 + "</span>"; }
      if (resp.retailer.city && resp.retailer.state)
        html += "<span class='city'>" + resp.retailer.city + ", " + resp.retailer.state + " " + resp.retailer.zip + "</span>";
      html += "</address>";
      $(".pickup-wrap .retailer address").replaceWith(html);
    }
  },

  add_in_store_pickup: function(retailerID = null) {
  var that = this;
  that.pickup_selected = true;
    if (!retailerID) {
      retailerID = that.local_roots_retailer_id;
    }

    if (retailerID && window.pickupRetailers && !window.pickupRetailers.includes(+retailerID)) {
      retailerID = null;
    }

    that.choose_pickup_retailer(retailerID);
    $("#in-store-discount").show();
    that.calculate_in_store_savings();
    $("#in-store-savings").show();
    // that.client.checkout.removeDiscount(that.checkout_id);
    $('.discount-tr').remove();
    $('.pickup-wrap').show();
    $("#cart-shipping-cost, .order-summary .discount").hide();
    $(".pickup-wrap .retailer").slideDown();
    $(".pickup-wrap .person").slideDown();
    // that.client.checkout.addDiscount(that.checkout_id, "In Store Pickup").then(checkout => {
    //   that.checkout = checkout;
    //   that.update_cart();
    // });
  },

  remove_in_store_pickup: function(dontSlide = false) {
    var that = this;
    that.pickup_selected = false;
    $("#in-store-discount").hide();
    $("#in-store-savings").hide();
    $("#cart-shipping-cost, .order-summary .discount").show();
    if (!dontSlide) {
      $(".pickup-wrap .retailer").slideUp();
      $(".pickup-wrap .person").slideUp();
    }
    $("#bag-cart-message").empty();
    $(".pickup-wrap .retailer address").empty();
    that.pickup_retailer_id = false;
    that.local_roots_retailer_id = window.retailer_id;
    that.choose_pickup_retailer(false);
    $.removeCookie('pickup_retailer')
    $.removeCookie('pickup_info');
  },

  check_options_valid: function(ring_id, is_pending_size) {
    var that = this;
    that.get_variant_for_options(ring_id, is_pending_size);
    // console.log("checking options valid");
    // console.log("variant_id: " + that.variant_id);
    var is_made_to_order = that.is_ring_made_to_order(ring_id);
    var el = ($('.RingModal').length > 0 && $(".RingModal").is(":visible")) ? $('.RingModal') : $(".content_body .ring-details#ring_" + ring_id).first();
    //console.log("is modal: " + (el.closest(".ring-overlay-modal").length > 0));
    var selected_width = el.find('.width.opt select').val();
    var eng_cb = el.find(".enable_engraving");
    //console.log("engraving checked: " + eng_cb.is(":checked"));
    var eng_tx = el.find("input.eng-text").val();
    var eng_valid = !eng_cb.is(":checked") || (eng_cb.is(":checked") && eng_tx && eng_tx != '');
    var width_label = el.find("label[for='ring_" + ring_id + "_width_select'], label[for='ring_" + ring_id + "_width_select-button']");
    var size_label = el.find("label[for='ring_" + ring_id + "_size_select-button']");

    size_label.toggleClass('req', !that.variant_id && !is_made_to_order);
    size_label.parent().toggleClass('req', !that.variant_id && !is_made_to_order);

    width_label.toggleClass("req", selected_width == "");
    width_label.parent().toggleClass("req", selected_width == "");

    const engravingTextInput = eng_cb.closest('.engraving').find('.eng-options .field');
    engravingTextInput.toggleClass('req', eng_cb.is(":checked") && eng_tx == '');

    var atc_btn = el.find(".shopify-add-to-cart[data-id=" + ring_id + "]");

    const shouldDisableButton = (eng_cb.is(":checked") && eng_tx == "") || (!that.variant_id && !is_made_to_order) || !((that.variant_id || is_made_to_order) && eng_valid) || (selected_width == "");
    atc_btn.toggleClass('disabled', shouldDisableButton);
  },

  after_init: async function() {
    var that = this;
    if ( that.ring_data ) { // make sure ring data is loaded, if not try again in 400ms
      $.each( $(".shopify-add-to-cart"), function(k,b) {
        var btn = $(b);
        var rid = btn.data("id");
        that.check_options_valid(rid, false);
      });
      $(".apply-discount-code").removeClass("disabled");
      await that.update_cart();
      that.update_cart_count();
    }
    else {
      setTimeout(function() {
        that.after_init();
      }, 400);
    }
  },

  update_cart_count: function() {
    var that = this;
    if ( that.lineItems ) {
      $(".shopify-cart-count").text( that.lineItems.length ).css("opacity","1");
      Cookies.set('caboose_shopify_cart_count', that.lineItems.length, { expires: 7 });
      $('#mobile-bag .NotEmptyIndicator,nav .bg .NotEmptyIndicator').toggleClass('hidden', that.lineItems.length === 0);
    }
  },

  is_ring_made_to_order: function(ring_id) {
    const $elementToCheck = $('.RingModal').length > 0 ? $('.RingModal') : $(".ring-details#ring_" + ring_id);
    return ($elementToCheck.data("madetoorder") == true || $elementToCheck.data("madetoorder") == 'true')
  },

  get_variant_for_options: function(ring_id, is_pending_size) {
    var that = this, vid = null;
    that.variant_id = null;
    is_pending_size = typeof is_pending_size !== 'undefined' ? is_pending_size : false;
    var el = $('.RingModal').length > 0 ? $('.RingModal') : $(".ring-details#ring_" + ring_id);
    if ( is_pending_size )
      vid = el.data("pendingsizevariantid");
    else
      vid = el.find(".ring-size-select option:selected").data("variantid");
    if ( vid ) { that.variant_id = that.get_long_variant_id(vid); }
  },

  init_carts: function() {
    var that = this;
    $.each( $(".shopify-cart-element"), function(k,el) {
      var cart = $(el);
      var load = $("<div />").addClass("cart-loading overlay").html("<div class='table'><div class='table-cell'><p>Updating bag...</p></div></div>");
      cart.append(load);
      that.cart_els.push(cart);
      if ( cart.attr("id") == "dropdown" )
        that.header_cart = cart;
    });
  },

  update_cart: async function() {
    var that = this;
    this.lineItems = this.lineItems = await window.cartProvider.getItems();

    $(".shopify-line-items").html("");
    // this.checkout = {lineItems: cartItems};
    // if ( cartItems && cartItems.length > 0 ) {

    if ( this.lineItems && this.lineItems.length > 0 ) {

      var nested_items_in_bag = [];
      var sizer_items = [];

      var nested_item_ids = [
        that.eng_script,
        that.eng_block,
        that.customization_line_id
      ].concat(that.free_ring_ids);

      // see if there are any pending size rings
      $.each( that.lineItems, function(_, item) {
        // console.dir(item.variant);
        var item_is_pending = item && item.variant && item.variant.title && item.variant.title.indexOf("Size: Pending") >= 0 ? true : false;
        // console.log("item_is_pending: " + item_is_pending);
        if ( item_is_pending ) {
          that.pending_size_ring_added = true;
         // console.log("found pending size ring, setting pending_size_ring_added to true");
        }
      });

      // print each line item
      $.each(that.lineItems, function(_, item) {
        if (!item.variant) {
          that.remove_line_item(item);
          return;
        }

        var short_id = item.variant ? that.get_short_variant_id(item.variant.id) : null;
        var product_id = item.variant && item.variant.product ? that.get_short_product_id(item.variant.product.id) : null;

        if (!product_id || !short_id || nested_item_ids.indexOf(+short_id) !== -1) {
          return;
        }

        if (short_id == that.sizer_id) {
          sizer_items.push(item);
        }

        var data = that.ring_data[product_id];
        var nested_item_line_ids = that.print_cart_line_item(item, data);
        nested_items_in_bag.push(nested_item_line_ids.engraving, nested_item_line_ids.free_ring, nested_item_line_ids.customization);
      });

      // remove any nested items that are not connected to a ring
      $.each(that.lineItems, function(_, item) {
        if (!item.variant) {
          return;
        }

        var short_id = that.get_short_variant_id(item.variant.id);
        if (nested_item_ids.indexOf(+short_id) !== -1 && nested_items_in_bag.indexOf(item.id) === -1) {
          that.remove_line_item(item);
        }
      });

      // remove extra sizer kits
      if ( sizer_items.length > 1 ) {
        $.each(sizer_items, function(sk,si) {
          if ( sk > 0 ) {
            that.remove_line_item(si);
          }
        });
      }

      // bag only has one sizing kit, no rings
      if ( sizer_items.length == that.lineItems.length ) {
        that.remove_in_store_pickup();
        $(".bag-page .pickup-wrap").hide();
        $(".bag-page form.discount").hide();
      } else {
        $(".bag-page .pickup-wrap").show();
      }

      if (!that.pickup_selected) {
        $("#in-store-pickup-checkbox").prop("checked",false).parent().removeClass("selected");
      }

      that.update_prices();
      $(".shopify-checkout-btn").removeClass("disabled");
      that.carts_loading(false);
    }
    else {
      $("#in-store-discount").hide();
      $(".bag-page form.discount").hide();
      $(".bag-page .pickup-wrap").hide();
      $("#in-store-savings").hide();
      $("#cart-shipping-cost").show();
      $(".pickup-wrap .retailer").hide();
      $(".pickup-wrap .person").hide();
      var li = $("<li />").addClass("shopify-line-item empty").text("Your bag is empty.");
      that.set_prices_zero();
      that.checkout_url = "#";
      that.pending_size_ring_added = false;
      $(".shopify-checkout-btn").attr("href","#").addClass("disabled");
      that.carts_loading(false);
      $.each( $(".shopify-line-items"), function(k,el) {
        var li2 = li.clone(false);
        $(el).append(li2);
      });
    }

    if (window.location.hash.indexOf('#select-your-free-ring:') === 0) {
      var lineId = window.location.hash.split(':').pop();
      history.pushState('', document.title, window.location.pathname + window.location.search);
      var $activator = $('.content_body [data-shopify-line-item-id="' + lineId + '"] [data-action="select-free-ring"]');
      $activator.click();
    }
  },

  format_currency: function(number) {
    let decPlaces = 2;
    let decSep = ".";
    let thouSep = ",";
    var sign = number < 0 ? "-$" : "$";
    var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
    var j = (j = i.length) > 3 ? j % 3 : 0;
    return sign +
      (j ? i.substr(0, j) + thouSep : "") +
      i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
      (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
  },

  calculate_in_store_savings: function() {
    var that = this;
    var savings = 0.0;
    if (that.pickup_selected )
      savings = this.getSubTotal() * 0.1;
    var savings_amount = "-" + that.format_currency(savings);
    $("#savings-amount").text(savings_amount);
  },

  update_prices: function() {
    var sp = this.format_currency(this.getSubTotal());
    $(".shopify-subtotal-price").text(sp);
    if (this.pickup_selected) {
      this.calculate_in_store_savings();
    }
    // var tp = that.format_currency(that.checkout.totalTax);
    // $(".shopify-tax-price").text(tp);
    // var op = that.format_currency(that.checkout.totalPrice);
    // $(".shopify-total-price").text(op);
  },

  set_prices_zero: function() {
    $(".shopify-subtotal-price").text("$0.00");
    $(".shopify-total-price").text("$0.00");
    $(".shopify-tax-price").text("$0.00");
  },

  carts_loading: function(is_loading) {
    var that = this;
    if ( is_loading ) {
      $.each( that.cart_els, function(k,el) {
        el.addClass("loading");
      });
      $.each( $(".shopify-subtotal-price, .shopify-tax-price, .shopify-total-price,#in-store-savings"), function(k,el) {
        $(el).hide();
      });
    }
    else {
      $.each( that.cart_els, function(k,el) {
        el.removeClass("loading");
      });
      $.each( $(".shopify-subtotal-price, .shopify-tax-price, .shopify-total-price"), function(k,el) {
        $(el).show();
      });
      if (this.pickup_selected) {
        $('#in-store-savings').show();
      }
    }
  },

  print_cart_line_item: function(item, data) {
    var that = this;
    var eng_id = null;
    var free_ring_line_id = null;
    var customization_line_id = null;
    var short_id = item.variant ? that.get_short_variant_id(item.variant.id) : null;

    if ( short_id != that.eng_script && short_id != that.eng_block ) {

      var li = $("<li />").addClass("shopify-line-item").attr('data-shopify-line-item-id', item.id);
      var flex = $("<div />").addClass("flexbox");

      var left = $("<div />").addClass("left");
      var center = $("<div />").addClass("center");
      var line = $("<div />").addClass("lineitem");
      var info = $("<div />").addClass("info");
      var right = $("<div />").addClass("right");

      var customizationID = that.get_line_item_property_value(item, '_customization_id');
      var dataURL = ((data || {}).url || null);
      var productURL = customizationID && dataURL ? `${dataURL}?customizationid=${customizationID}` : dataURL;

      if ( item.variant && item.variant.id == that.sizer_long_id )
        that.sizer_added = true;

      if ( item.variant && item.variant.image ) {
        var mb = item.variant.id == that.sizer_long_id ? "5px" : "";
        var image = $("<div />").addClass("image").css("margin-bottom",mb);
        var img = $("<img />").attr("src", item.variant.image.src).attr("alt", item.variant.image.altText);
        var lk = productURL ? ($("<a />").attr("href", productURL)) : $("<div />");
        lk.append(img);
        image.append(lk);
        if ( data && data.green ) {
          var gn = $("<img />").attr("src", "//assets.benchmarkrings.com/assets/green_1x.png").attr("width","7").attr("height","16").addClass("gn");
          image.append(gn);
        }
        left.append(image);
      }
      else if ( data && data.full_style_number ) { // fall back to Caboose ring image
        var image = $("<div />").addClass("image");
        var img = $("<img />").attr("src", "https://assets.benchmarkrings.com/benchmark_rings/ring_" + data.full_style_number + "_1_small.jpg").attr("alt", data.full_style_number);
        var lk = productURL ? ($("<a />").attr("href", productURL)) : $("<div />");
        lk.append(img);
        image.append(lk);
        if ( data && data.green ) {
          var gn = $("<img />").attr("src", "//assets.benchmarkrings.com/assets/green_1x.png").attr("width","7").attr("height","16").addClass("gn");
          image.append(gn);
        }
        left.append(image);
      }

      var text = $("<div />").addClass("text");
      var ptitle = $("<h5 />").addClass("product-title").html( data && data.short_description ? data.short_description : item.title );
      var vtitle = "";

      if ( item.variant && item.variant.id != that.sizer_long_id ) {
        $.each( item.variant.selectedOptions, function(_, op) {
          var h6 = "<h6 class='variant-title'><span>" + op.value + "</span></h6>";
          vtitle += h6;
        });
      }

      info.append(text);
      line.append(info).append(right);

     //  var sizer_is_required = false;

      var nested_lines = [];

      if (item.variant) {
        var engraving_link = that.get_line_item_property_value(item, '_engraving_sku');
        var free_ring_link = that.get_line_item_property_value(item, '_free_ring_link');
        var free_ring_eligible = that.get_line_item_property_value(item, '_free_ring_eligible') === '1';
        var customization_link = that.get_line_item_property_value(item, '_customization_link');

        if (free_ring_eligible && !free_ring_link) {
          var $free_ring_cta = $("<a />")
            .addClass('free-ring-cta')
            .attr('href', '/bag#select-your-free-ring:' + item.id)
            .attr('data-action', 'select-free-ring')
            .data('parentLineItem', item)
            .html(that.free_ring_cta_text);

          info.append($free_ring_cta);
        }

        $.each(that.lineItems, function(_, nested_line_item) {
          if (!nested_line_item || !nested_line_item.customAttributes || item.id === nested_line_item.id || !nested_line_item.variant) {
            return;
          }

          $.each(nested_line_item.customAttributes, function(_, op) {
            // if ( op.key == "_required" && op.value == "true" ) {
            //   sizer_is_required = true;
            // }
            if (!op.value) {
              return;
            }

            if (op.key == "_engraving_sku" && op.value == engraving_link) {
              eng_id = nested_line_item.id;
              nested_lines.push(that.get_nested_cart_line_item_element(ShopifyController.ItemType.ENGRAVING, nested_line_item, item));
            } else if (op.key === '_free_ring_link' && op.value === free_ring_link) {
              free_ring_line_id = nested_line_item.id;
              nested_lines.push(that.get_nested_cart_line_item_element(ShopifyController.ItemType.FREE_RING, nested_line_item, item));
            } else if (op.key === '_customization_link' && op.value === customization_link) {
              customization_line_id = nested_line_item.id;
              nested_lines.push(that.get_nested_cart_line_item_element(ShopifyController.ItemType.CUSTOMIZATION, nested_line_item, item));
            }
          });
        });
      }


      var links = $("<div />").addClass("links");

      var lk1 = $("<a />").attr("href","#").text("Edit").click(function(e) {
        e.preventDefault();
        var event_category = $(this).closest("header.main").length > 0 ? "Header" : "Shopping Bag Page";
        var event_name = event_category == "Header" ? 'Bag Preview - Clicked Edit' : 'Clicked Edit';
        trigger_event(event_name, event_category, '', 1);
        that.open_edit_modal( item, data );
      });

      if ( item.variant && item.variant.id == that.sizer_long_id ) {
        lk1 = '';
      }

      var lk2 = $("<a />").attr("href","#").text("Remove").click(function(e) {
        e.preventDefault();
        var event_category = $(this).closest("header.main").length > 0 ? "Header" : "Shopping Bag Page";
        var event_name = event_category == "Header" ? 'Bag Preview - Clicked Remove' : 'Clicked Remove';
        trigger_event(event_name, event_category, '', 1);
        $(this).closest("li.shopify-line-item").remove();
        that.carts_loading(true);
        that.remove_line_item(item);
      });

      // remove 'Remove' link on Ring Sizing Kit because there is a pending size ring in the cart
      if ( item.variant && item.variant.id == that.sizer_long_id && that.pending_size_ring_added && that.lineItems.length > 1 ) {
        lk2 = '';
      }

      links.append(lk1).append(lk2);

      var customization_link = item.customAttributes.find(a => a.key === '_customization_link');

      if ( item.variant && item.variant.id != that.sizer_long_id && !customization_link ) {

        var sqs = $("<div />").addClass("shopify-quantity-selector");
        var lbl = $("<label />").text("Qty:");
        var dec = $("<a />").attr("href","#").addClass("btn q dec").text("-").toggleClass('disabled', item.quantity === 1);
        var inc = $("<a />").attr("href","#").addClass("btn q inc").text("+");
        var span = $("<span />").addClass("quantity-span").text(item.quantity);
        var inp = $("<input />").attr("type","hidden").addClass("shopify-quantity-input").attr("name","quantity").val(item.quantity).attr("min","1");

        sqs.append(lbl).append(dec).append(span).append(inp).append(inc);

        inc.on("click", function(e) {
          e.preventDefault();
          that.updateQuantity(this, 1);
        });

        dec.on("click", function(e) {
          e.preventDefault();
          that.updateQuantity(this, -1);
        });

        left.append(links);
        info.append(sqs);
      }
      else {
        left.append(links);
      }

      center.append(line).append(nested_lines);

      if (item.variant) {
        var pc = item.variant.price.amount > 0 ? that.format_currency(item.variant.price.amount).replace(".00","") : 'FREE';
        var price = $("<span />").addClass("price current").text(pc);
        right.append(price);
      }

      text.append(ptitle).append(vtitle);

      flex.append(left).append(center);
      li.append(flex);

      $.each( that.cart_els, function(k,el) {
        var items = el.find(".shopify-line-items");
        var li2 = li.clone(true);
        items.append(li2);
      });
    }

    return {
      engraving: eng_id,
      free_ring: free_ring_line_id,
      customization: customization_line_id
    };
  },

  get_line_item_property: function(line_item, key) {
    var that = this;

    if (typeof line_item === 'string') {
      $.each(that.lineItems, function(_, i) {
        if (typeof line_item !== 'string') {
          return;
        }

        if (i.id === line_item) {
          line_item = i;
        }
      });
    }

    var matchingProperty = (line_item.customAttributes || [])
      .filter(function(prop) {
        return prop.key === key;
      });

    return matchingProperty.length === 1 ? matchingProperty[0] : null;
  },

  get_line_item_property_value: function(line_item, key) {
    var prop = this.get_line_item_property(line_item, key);
    return prop ? prop.value : null;
  },

  get_linked_line_item: function(parent_line_id, link_key, value) {
    var that = this;
    var line_item = null;

    $.each(that.lineItems, function(_, i) {
      if (parent_line_id === i.id || line_item) {
        return;
      }

      var is_match = i.customAttributes && i.customAttributes.filter(function(prop) { return prop.key === link_key && prop.value === value; }).length === 1;
      if (is_match) {
        line_item = i;
      }
    });

    return line_item;
  },

  get_nested_cart_line_item_element: function(item_type, line_item, parent_line_item) {
    var that = this;
    var $nested_line = $("<div />").addClass("lineitem flexbox nested").attr('data-id', line_item.id);
    var info = $("<div />").addClass("info");
    var right = $("<div />").addClass("right");
    var left = $('<div />').addClass('left');
    var center = $('<div />').addClass('center');
    var image_container = $('<div />').addClass('image');
    var img = $("<img />");
    var text = $("<div />").addClass("text");
    var ptitle = $("<h5 />").addClass("product-title");
    var original_price = null;

    var product_title = line_item.title;
    if (item_type === ShopifyController.ItemType.FREE_RING) {
      img.attr('src',  line_item.variant.image.src);
      original_price = '$' + (+line_item.variant.compareAtPrice.amount).toFixed(2).replace('.00', '');
    }

    ptitle.text(product_title);
    var vtitle = "";

    if (item_type === ShopifyController.ItemType.ENGRAVING) {
      (parent_line_item.customAttributes || [])
        .filter(function(prop) {
          return prop.key && prop.value && prop.key.toLowerCase().startsWith('engraving') && prop.key.indexOf('_') !== 0;
        })
        .forEach(function forEachProp(prop) {
          var h6 = "<h6 class='variant-title'>" + prop.key.replace("Engraving ","") + ": " + "<span>" + prop.value + "</span></h6>";
          vtitle += h6;
        });
    } else if (item_type === ShopifyController.ItemType.FREE_RING) {
      var variant_title_parts = line_item.variant.title.split('/');
      variant_title_parts.pop(); // remove .option2 "Retail Value"
      vtitle += "<h6 class='variant-title'><span>" + variant_title_parts.join('/').trim() + "</span></h6>";

      (line_item.customAttributes || [])
        .filter(function(prop) {
          return prop.key && prop.value && prop.key.indexOf('_') !== 0;
        })
        .forEach(function forEachProp(prop) {
          var h6 = "<h6 class='variant-title'>" + (prop.key === 'Style' ? '' : prop.key + ': ') + "<span>" + prop.value.split(',').pop().trim() + "</span></h6>";
          vtitle += h6;
        });
    } else if (item_type === ShopifyController.ItemType.CUSTOMIZATION) {
      (parent_line_item.customAttributes || [])
      .filter(function(prop) {
        return prop.key && prop.value && prop.key.indexOf('_') !== 0 && prop.key.toLowerCase().includes('customization');
      })
      .forEach(function forEachProp(prop) {
        var key = prop.key.replace('Customization ', '');
        var h6 = "<h6 class='variant-title'>" + key + ": " + "<span style=\"white-space: break-spaces;\">" + prop.value + "</span></h6>";
        if (key == '') {
          var h6 = "<h6 class='variant-title'>" + "<span style=\"white-space: break-spaces;\">" + prop.value + "</span></h6>";
        }
        vtitle += h6;
      });
    }

    text.append(ptitle).append(vtitle);

    var links = $("<div />").addClass("links");

    var lk = $("<a />").attr("href","#").text("Remove").click(function(e) {
      e.preventDefault();
      $(this).closest(".lineitem").remove();
      that.carts_loading(true);
      that.remove_line_item(line_item);
    });

    links.append(lk);

    var pc = line_item.variant.price.amount > 0 ? ("$" + (line_item.variant.price.amount * line_item.quantity).toString().replace(".00","")) : 'FREE';
    var price = $("<span />").addClass("price current").text(pc);
    right.append(price);

    if (original_price) {
      var old_price = $('<span />').addClass('price old').html(original_price);
      price.addClass('sale').after(old_price);
    }

    info.append(text).append(links);
    center.append(info);

    if (item_type === ShopifyController.ItemType.FREE_RING) {
      image_container.append(img);
      left.append(image_container);
      $nested_line.append(left);
    }

    $nested_line.append(center).append(right);

    return $nested_line;
  },

  open_edit_modal: function(item, data) {

    var that = this;

    var use_engraving = false;
    var engraving_text = null;
    var engraving_font = null;
    var size_id = null;
    var customization_id = '';

    if ( item && item.customAttributes ) {
      $.each( item.customAttributes, function(k2, op) {
        if ( op.key == "Engraving Font" && op.value != "" ) {
          engraving_font = op.value
          use_engraving = true
        }
        else if ( op.key == "Engraving Text" && op.value != "" ) {
          engraving_text = op.value
        } else if (op.key == '_customization_id' && op.value != "") {
          customization_id = op.value;
        }
      });
    }

    if ( item.variant && item.variant.title != "" ) {
      var mat = item.variant.title.match(/Size: (\d\.?\d?)/);
      var size = mat && mat.length > 1 ? parseFloat(mat[1]) : null;
      var s = size ? that.size_data[size] : null;
      size_id = s ? s.id : 0;
    }

    // show_ring_modal(data.id, false, size_id, use_engraving, engraving_text, engraving_font, item.quantity, item.id, customization_id);

    const lineItemData = {
      size: size_id,
      engraving: use_engraving,
      text: engraving_text,
      font: engraving_font,
      quantity: item.quantity,
      edit_mode: true,
      line_item_id: item.id,
      customization_id
    };

    showRingModal(data.id, lineItemData);

  },

  show_confirmation_modal: function(el) {
    var that = this;
    $(".fullscreen-overlay.confirm").remove();
    var fso = $("<div />").addClass("fullscreen-overlay confirm");
    var mw = $("<div />").addClass("modal-wrapper");
    var md = $("<div />").addClass("modal");
    var h4 = $("<h4 />").text("Wait! Are you sure?");
    var par = $("<div />").addClass("richtext").html(that.confirm_text);
    var img = $("<img />").attr("src","https://assets.benchmarkrings.com/media/6337256_sizer2x_large.jpg").attr("alt","Ring Sizing Kit").css("width","240px");
    var btn = $("<a />").attr("href","#").addClass("btn").text("Order Ring Sizing Kit").click(function(e) {
      e.preventDefault();
      that
        .add_to_cart(el, true)
        .then(that.update_page_after_cart_update.bind(that))
        .then(function() {
          fso.fadeOut();
          enableScroll();
        });
    });
    var atc = $("<a />").attr("href","#").addClass("atc").text("No thanks.").click(function(e) {
      e.preventDefault();
      that
        .add_to_cart(el, false)
        .then(that.update_page_after_cart_update.bind(that))
        .then(function() {
          fso.fadeOut();
          enableScroll();
        });
    });
    md.append(h4).append(par).append(img).append(btn).append(atc);
    mw.append(md);
    fso.append(mw);
    $("body").append(fso);
    fso.fadeIn();
    disableScroll();
    var event_context = 'Product Detail Page';
    if ( el && el.closest(".ring-modal").length == 1 ) { event_context = 'Quick View'; }
    trigger_event('Shown Ring Sizing Kit Pop Up', event_context, '', 1);
  },

  update_line_item_quantity: async function(line_item, new_quantity) {
    var that = this;
    var line_item_id = line_item.id;
    var lineItemsToUpdate = [
      {
        id: line_item_id,
        quantity: parseInt(new_quantity),
        variantId: line_item.variant.id,
        customAttributes: line_item.customAttributes,
      }
    ];
    var engraving_link = null;
    var free_ring_link = null;
    var customization_link = null;
    $.each( line_item.customAttributes, function(k2, op) {
      if (op.key == "_engraving_sku") {
        engraving_link = op.value;
      } else if (op.key == '_free_ring_link') {
        free_ring_link = op.value;
      } else if (op.key === '_customization_link') {
        customization_link = op.value;
      }
    });

    var vid = line_item.variant ? parseInt(that.get_short_variant_id(line_item.variant.id)) : null;

    // Find corresponding engraving products
    $.each( that.lineItems, function(k, item) {
      if ( item && item.customAttributes && line_item.variant ) {
        $.each( item.customAttributes, function(k2, op) {
          if ( op.key == "_engraving_sku" && op.value != "" && op.value == engraving_link && item.id != line_item.id && vid != that.eng_script && vid != that.eng_block ) {
            lineItemsToUpdate.push({
              id: item.id,
              variantId: (item.variant || {}).id,
              quantity: parseInt(new_quantity),
              customAttributes: item.customAttributes,
            });
          }

          if ( op.key == "_free_ring_link" && op.value != "" && op.value == free_ring_link && item.id != line_item.id ) {
            lineItemsToUpdate.push({
              id: item.id,
              variantId: (item.variant || {}).id,
              quantity: parseInt(new_quantity),
              customAttributes: item.customAttributes,
            });
          }

          if ( op.key == "_customization_link" && op.value != "" && op.value == customization_link && item.id != line_item.id ) {
            var customizationFee = (line_item.customAttributes.find(a => a.key === '_customization_fee') || {}).value || 0;
            lineItemsToUpdate.push({
              id: item.id,
              variantId: (item.variant || {}).id,
              quantity: parseInt(customizationFee * new_quantity),
              customAttributes: item.customAttributes,
            });
          }
        });
      }
    });

    await Promise.all(lineItemsToUpdate.map(li => window.cartProvider.updateItem(li.id, li)));
    await that.set_checkout_state();
  },

  update_line_item_properties: async function(lineItem, new_properties) {
    var that = this;

    var lineItemUpdate = {
      quantity: +lineItem.quantity,
      variantId: (lineItem.variant || {}).id,
      customAttributes: new_properties
    };

    await window.cartProvider.updateItem(lineItem.id, lineItemUpdate);
    return that.set_checkout_state();
    // return that.client.checkout.updateLineItems(that.checkout_id, lineItemUpdate)
    //   .then(that.set_checkout_state.bind(that));
  },

  remove_line_item: async function(line_item, after) {
    var that = this;
    var line_item_id = line_item.id;
    var lineItemsToRemove = [line_item_id];
    var lineItemsToUpdate = [];
    var engraving_link = that.get_line_item_property_value(line_item, '_engraving_sku');
    var free_ring_link = that.get_line_item_property_value(line_item, '_free_ring_link');
    var customization_link = that.get_line_item_property_value(line_item, '_customization_link');

    var parent_line_variant_id = line_item.variant ? parseInt(that.get_short_variant_id(line_item.variant.id)) : null;
    var pending_size_count = 0, sizer_line_item = null;

    // Loop through existing line items in cart
    $.each( that.lineItems, function(_, item) {

      var item_is_pending = item && item.variant && item.variant.title && item.variant.title.indexOf("Size: Pending") >= 0 ? true : false;

      // Count the number of pending size rings
      if ( item_is_pending )
        pending_size_count += 1;

      if ( item.variant ) {
        var var_id = parseInt(that.get_short_variant_id(item.variant.id));
        // Get sizing kit line item
        if ( var_id == that.sizer_id )
          sizer_line_item = item;
      }

      if (item.customAttributes) {
        var keys_to_sync = ['_engraving_sku', '_free_ring_link', '_customization_link'];
        $.each(item.customAttributes, function(_, op) {
          if (!op.value || item.id === line_item.id || keys_to_sync.indexOf(op.key) === -1) {
            return;
          }

          if (op.key === "_engraving_sku" && op.value === engraving_link) {
            if (parent_line_variant_id !== that.eng_script && parent_line_variant_id !== that.eng_block) {
              // clicked Remove on a Ring product, so we need to remove the Engraving product as well
              lineItemsToRemove.push(item.id);
            } else {
              // clicked Remove on the Engraving product, so we need to clear the linked Ring of it's engraving properties
              var updated_properties = [];
              var props_to_clear = ['Engraving Text', 'Engraving Font', '_engraving_sku'];
              $.each(item.customAttributes, function(_, op2) {
                if (props_to_clear.indexOf(op2.key) === -1) {
                  updated_properties.push({
                    key: op2.key,
                    value: op2.value
                  });
                }
              });

              lineItemsToUpdate.push({
                id: item.id,
                variantId: (item.variant || {}).id,
                customAttributes: updated_properties,
                quantity: +item.quantity
              });
            }
          } else if (op.key === "_free_ring_link" && op.value === free_ring_link) {
            if (that.free_ring_ids.indexOf(parent_line_variant_id) === -1) {
              // clicked Remove on a Ring product, so we need to remove the Free Ring item as well
              lineItemsToRemove.push(item.id);
            } else {
              // clicked Remove on the Free Ring product, so we need to clear the linked Ring of it's Free Ring link
              var updated_properties = [];
              var props_to_clear = ['_free_ring_link'];
              $.each(item.customAttributes, function(_, op2) {
                if (props_to_clear.indexOf(op2.key) === -1) {
                  updated_properties.push({
                    key: op2.key,
                    value: op2.value
                  });
                }
              });

              lineItemsToUpdate.push({
                id: item.id,
                variantId: (item.variant || {}).id,
                customAttributes: updated_properties,
                quantity: +item.quantity
              });
            }
          } else if (op.key === "_customization_link" && op.value === customization_link) {
            if (that.customization_line_id !== parent_line_variant_id) {
              // clicked Remove on a Ring product, so we need to remove the Free Ring item as well
              lineItemsToRemove.push(item.id);
            } else {
              // clicked Remove on the Free Ring product, so we need to clear the linked Ring of it's Free Ring link
              var updated_properties = [];
              var props_to_clear = ['_customization_link'];
              $.each(item.customAttributes, function(_, op2) {
                if (props_to_clear.indexOf(op2.key) === -1) {
                  updated_properties.push({
                    key: op2.key,
                    value: op2.value
                  });
                }
              });

              lineItemsToUpdate.push({
                id: item.id,
                variantId: (item.variant || {}).id,
                customAttributes: updated_properties,
                quantity: +item.quantity
              });
            }
          }
        });
      }
    });

    if ( parent_line_variant_id == that.sizer_id )
      that.sizer_added = false;

    const freeRingLinkAttribute = line_item.customAttributes.find(ca => ca.key === '_free_ring_link')
    const freeRing = (!freeRingLinkAttribute || !freeRingLinkAttribute.value) ? null : that.lineItems.find(li => li.id !== line_item.id && (li.customAttributes.find(ca => ca.key === '_free_ring_link') || {}).value === freeRingLinkAttribute.value);

    // Remove sizing kit if no other pending size rings in cart
    var item_is_pending = line_item && line_item.variant && line_item.variant.title && line_item.variant.title.indexOf("Size: Pending") >= 0 ? true : false;
    var isFreeRingPending = freeRing && (freeRing.customAttributes.find(ca => ca.key === 'Size') || {}).value === 'Pending';
    if ((item_is_pending || isFreeRingPending) && pending_size_count < 2 && sizer_line_item ) {
      lineItemsToRemove.push(sizer_line_item.id);
      that.sizer_added = false;
    }

    if (lineItemsToRemove) {
      await Promise.all(lineItemsToRemove.map(l => window.cartProvider.removeItem(l)));
    }

    if (lineItemsToUpdate) {
      await Promise.all(lineItemsToUpdate.map(l => window.cartProvider.updateItem(l.id, l)));
    }

    await that.set_checkout_state();
    if (after) {
      after();
    }
    // that.client.checkout.removeLineItems(that.checkout_id, lineItemsToRemove).then((checkout) => {
    //   if ( lineItemsToUpdate.length == 0 ) {
    //     that.set_checkout_state(checkout);
    //     if ( after ) { after(); }
    //   } else {
    //     that.client.checkout.updateLineItems(that.checkout_id, lineItemsToUpdate).then((checkout) => {
    //       that.set_checkout_state(checkout);
    //       if ( after ) { after(); }
    //     });
    //   }
    // });
  },

  show_cart: function() {
    var that = this;
    that.header_cart.show().addClass("show");
    if ( $(window).width() < 800 ) {
      disableScroll();
    }
  },

  toggle_cart: function() {
    var that = this;
    if ( that.header_cart.hasClass("show") )
      that.hide_cart();
    else
      that.show_cart();
  },

  hide_cart: function() {
    var that = this;
    that.header_cart.hide().removeClass("show");
    $(".shopify-close-cart").hide();
    enableScroll();
  },

  update_item_in_cart: function(btn) {
    var that = this;
    var lid = $(btn).data("line-item");
    var free_ring_link = that.get_line_item_property_value(lid, '_free_ring_link');
    var free_ring = free_ring_link ? that.get_linked_line_item(lid, '_free_ring_link', free_ring_link) : null;

    $.each(that.lineItems, function(_, item) {
      if ( lid == item.id ) {
        var item_is_pending = $(".ring-modal .ring-size-select option:selected").text() == "Pending";
        that.carts_loading(true);
        that.remove_line_item(item, function() {
          that.carts_loading(true);
          that
            .add_to_cart(btn, item_is_pending)
            .then(function (ctx) {
              if (!free_ring || !Array.isArray(ctx.addedLines) || ctx.addedLines.length === 0) {
                return that.update_page_after_cart_update(ctx);
              }

              that.carts_loading(true);

              var addedLine = ctx.addedLines[0];

              var added_item_properties = [];
              (addedLine.customAttributes || []).forEach(function(prop) {
                added_item_properties.push({ key: prop.key, value: prop.value });
              });

              added_item_properties.push({ key: '_free_ring_link', value: free_ring_link });

              var ring_size = addedLine.variant.selectedOptions[1].value.split(':').pop().trim();
              var line_items_to_add = [{
                variantId: free_ring.variant.id,
                quantity: addedLine.quantity,
                customAttributes: [
                  { key: '_free_ring_link', value: free_ring_link },
                  { key: 'Size', value: ring_size }
                ]
              }];

              return that.update_line_item_properties(addedLine, added_item_properties)
                .then(that.add_items_to_shopify_cart.bind(that, line_items_to_add))
                .then(that.update_page_after_cart_update.bind(that));
            });
        });
      }
    });
  },

  add_to_cart: async function(btn, is_pending_size) {
    var that = this;
    var form = btn.closest('form');
    var rid = btn.data("id");
    is_pending_size = typeof is_pending_size !== 'undefined' ? is_pending_size : false;

   // console.log("adding to cart, is_pending_size: " + is_pending_size);

    that.get_variant_for_options(rid, is_pending_size);

   // console.log("variant_id: " + that.variant_id);

    if (!that.variant_id) {
      return;
    }

    that.bag_hover_start = false;

    var add_engraving = false;
    var engraving_font = 'Block'
    var engraving_text = '';
    var quant = 1;
    var event_context = btn.closest(".ring-modal").length == 1 ? "Quick View" : "Product Detail Page";

    var eng_link_id = (Math.floor(Math.random() * 999999)).toString();

    if ( rid ) {
      var el = ($('.RingModal').length > 0 && $(".RingModal").is(":visible")) ? $('.RingModal') : $(".content_body .ring-details#ring_" + rid).first();
      add_engraving =   el.find(".enable_engraving").is(":checked");
      engraving_font =  el.find(".engraving_font_select:checked").val();
      engraving_text =  el.find(".eng-text").val() ? el.find(".eng-text").val().replace(/[^\x20-\x21\x26-\x29\x2b-\x3f\x41-\x5d\x61-\x7a]/g, '') : '';
      quant = $('.RingModal').length > 0 ? +(el.find('.AddToBagButton').data('quantity') || 1) : parseInt( el.find(".shopify-quantity-input").val() || 1);
      var ring_name = btn.closest(".ring, .ring-details").data("style");
      var ring_type = btn.closest(".ring-details").data("madetoorder") == false ? "RTS" : "MTO";
      if (ring_name) { trigger_event(('Added ' + ring_type + ' Item To Bag'), event_context, ring_name, 1); }
    }

    var lineItemsToAdd = [
      {
        variantId: that.variant_id,
        quantity: quant,
        customAttributes: []
      }
    ];

    if ( add_engraving ) {
      var eng_var = engraving_font == "Block" ? that.eng_block : that.eng_script;
      lineItemsToAdd.push({
        variantId: that.get_long_variant_id(eng_var),
        quantity: quant
      });
    }

    // add ring sizing kit to cart for pending size rings
    if ( is_pending_size && !that.sizer_added ) {
      var sid = that.get_long_variant_id(that.sizer_id);
      lineItemsToAdd.push({
        variantId: sid,
        quantity: 1
      });
      that.sizer_added = true;
      trigger_event('Added Ring Sizing Kit To Bag', event_context, '', 1);
    }

    if ( add_engraving && engraving_font && engraving_text && engraving_text != '' ) {
      lineItemsToAdd[0].customAttributes.push({ key: "Engraving Font", value: engraving_font });
      lineItemsToAdd[0].customAttributes.push({ key: "Engraving Text", value: engraving_text });
      lineItemsToAdd[0].customAttributes.push({ key: "_engraving_sku", value: eng_link_id });
      lineItemsToAdd[1].customAttributes = [
        { key: "_engraving_sku", value: eng_link_id }
      ];
    }

    if (form.data('free-eligible') && that.free_ring_long_ids.length > 0) {
      lineItemsToAdd[0].customAttributes.push({
        key: '_free_ring_eligible',
        value: '1'
      });
    }

    var customizationId = window.customizationId;
    var customizationIdLineItem = { key: "_customization_id", value: customizationId };

    var customizationLink = null;

    if (customizationId) {
      customizationLink = crypto.getRandomValues( new Uint8Array(6)).join('');
      var customizationLinkProperty = { key: "_customization_link", value: customizationLink };
      var customizationFee = { key: "_customization_fee", value: (window.customizationFee || 0).toString() };
      var customizationLinkProperty = { key: "_customization_link", value: customizationLink }
      var customizationDescription = { key: "Customization Description", value: window.customizationDescription }

      lineItemsToAdd[0].customAttributes.push(customizationIdLineItem, customizationLinkProperty, customizationDescription, customizationFee);
      lineItemsToAdd.push({
        variantId: that.get_long_variant_id(window.customizationProductID),
        quantity: window.customizationFee * lineItemsToAdd[0].quantity,
        customAttributes: [customizationLinkProperty, customizationIdLineItem, customizationDescription, customizationFee]
      });
    }

    return that.add_items_to_shopify_cart(lineItemsToAdd, btn);
  },

  add_items_to_shopify_cart: async function(items, addToCartBtn) {
    var IGNORE_EVENT_TRACKING_SKUS = [
      'ENGRAVEBLOCK',
      'ENGRAVESCRIPT'
    ];

    var that = this;
    await this.initCart();
    return window.cartProvider.addItems(items).then((updatedCartItems) => {
      this.lineItems = updatedCartItems;
        that.set_checkout_state();
        var addedLines = that.find_matching_line_items(items);
        addedLines.forEach(function(addedLine) {
          if (IGNORE_EVENT_TRACKING_SKUS.indexOf(addedLine.variant.sku) !== -1) {
            return;
          }

          trigger_standard_event(StandardEvent.ADD_TO_CART, {
            style_number: addedLine.variant.sku,
            price: +addedLine.variant.price.amount,
            quantity: 1
          });
        });

        return {
          addToCartBtn: addToCartBtn,
          addedLines: addedLines
        };
      });
    // return that.client.checkout.addLineItems(that.checkout_id, items)
      // .then((checkout) => {
      //   that.set_checkout_state(checkout);
      //   var addedLines = that.find_matching_line_items(items);
      //   addedLines.forEach(function(addedLine) {
      //     if (IGNORE_EVENT_TRACKING_SKUS.indexOf(addedLine.variant.sku) !== -1) {
      //       return;
      //     }

      //     var isPendingSize = addedLine.variant.sku.indexOf('_PENDING') !== -1;
      //     var isFreeRing = addedLine.variant.sku.indexOf('FR-') === 0;
      //     var styleNumber = addedLine.variant.sku;
      //     if (isPendingSize) {
      //       styleNumber = styleNumber.replace('_PENDING', '');
      //     } else if (isFreeRing) {
      //       styleNumber = styleNumber.replace('FR-', '');
      //     } else {
      //       styleNumber = styleNumber.split('.')[0].slice(0, -2);
      //     }

      //     trigger_standard_event(StandardEvent.ADD_TO_CART, {
      //       style_number: styleNumber,
      //       price: +addedLine.variant.price.amount,
      //       quantity: 1
      //     });
      //   });

      //   return {
      //     addToCartBtn: addToCartBtn,
      //     addedLines: addedLines
      //   };
      // });
  },

  find_matching_line_items: function(itemsToFind) {
    var that = this;
    var matchingLines = [];

    itemsToFind.forEach(function(itemToFind) {
      var matchingLine = that.find_matching_line_item(itemToFind);
      if (matchingLine) {
        matchingLines.push(matchingLine);
      }
    });

    return matchingLines;
  },

  find_matching_line_item: function(itemToFind) {
    return this.lineItems.filter(function(lineItem) {
      var isSameVariant = lineItem.variant.id === itemToFind.variantId;

      if (!lineItem.customAttributes && itemsToFind.customAttributes) {
        var hasIdenticalProperties = false;
      } else {
        var mismatchedProperties = lineItem.customAttributes.filter(function(inCartCustomAttribute) {
          if (!itemToFind.customAttributes) {
            return true;
          }

          var matchingProperty = itemToFind.customAttributes.filter(function(toFindCustomAttribute) {
            return toFindCustomAttribute.key === inCartCustomAttribute.key;
          }).shift();

          return !matchingProperty || matchingProperty.value !== inCartCustomAttribute.value;
        });
        var hasIdenticalProperties = mismatchedProperties.length === 0;
      }

      return isSameVariant && hasIdenticalProperties;
    }).shift();
  },

  update_page_after_cart_update: async function(ctx) {
    var that = this;
    if ( $(".shopify-cart-element#page").length == 0 ) {
      that.show_cart();
    }
    dismissRingModal();

    if (ctx.addToCartBtn) {
      ctx.addToCartBtn.removeClass("disabled");
    }

    var is_hovered = false;
    $(".shopify-cart-element#dropdown").mouseenter(function(e) {
      is_hovered = true;
    });
    $(".shopify-cart-element#dropdown").mouseleave(function(e) {
      is_hovered = false;
    });
    setTimeout(function(){ if ( !is_hovered ) { that.hide_cart(); } }, 3000);

    return ctx;
  },

  set_checkout_state: async function() {
    // this.checkout = checkout;
    await this.update_cart();
    this.update_cart_count();
  },

  get_long_product_id: function(short_id) {
    var short = "gid://shopify/Product/" + short_id;
    return short;
  },

  get_long_variant_id: function(short_id) {
    var short = "gid://shopify/ProductVariant/" + short_id;
    return short;
  },

  get_short_variant_id: function(long_id) {
    return long_id.replace("gid://shopify/ProductVariant/","");
  },

  get_short_product_id: function(long_id) {
    return long_id.replace("gid://shopify/Product/","");
  },
  getSubTotal: function() {
    return this.lineItems.reduce((sub, li) => sub + +li.variant.price.amount * +li.quantity, 0);
  },
  initCart: async function() {
    const cartKey = Cookies.get('cart_key');
    if (!cartKey) {
      this.cart = await window.cartProvider.create();
    } else {
      try {
        this.cart = await window.cartProvider.get();
      } catch (e) {
        console.error(e);
        this.cart = await window.cartProvider.create();
      }
    }
    this.lineItems = await window.cartProvider.getItems();
  }
}