export class CollectionSortFilterController {
  constructor(selectedFilters = []) {
    this.selectedFilters = [];
    this.possible_filters = [
      'material',
      'color',
      'gemstone',
      'green',
      'made_to_order',
      'q',
      'free_eligible',
      'previewable',
      'parent_ring',
      'design_category'
    ];

    this.hookEvents();

    this.selectedFilters = selectedFilters;
    this.updateDisplayedFilters();
    this.setSortByFromURL();
    $('.filter-bar').toggleClass('collapsible', window.innerWidth <= 800);
  }

  hookEvents() {
    $(document).on('click', '.sorter-wrap', (e) => {
      if ( $(window).width() < 800 ) {
        this.injectSortModal();
        $('#sort-modal').fadeIn();
      }
    });

    $(document).on('click', '#sort-modal a.sc', (e) => {
      e.preventDefault();
      const $target = $(e.target);
      $('#sort-modal a.sc').removeClass('selected');
      var sort = $target.data('sort');
      $target.addClass('selected');
      $('#ring_sort').val(sort);
      $('#ring_sort')[0].dispatchEvent(new Event('change'));
      $('#sort-modal').fadeOut();
    });

    $(document).on('click', '#mobile-filter', (e) => {
      e.preventDefault();
      $('body > .mm-page').css('z-index',15);
      $('aside.filter-bar').fadeIn();
    });

    $(document).on('click', '#close-sort-modal', (e) => {
      e.preventDefault();
      $('#sort-modal').fadeOut();
    });

    $(document).on('click', '#close-filter', (e) => {
      e.preventDefault();
      $("aside.filter-bar").fadeOut();
      $("body > .mm-page").css("z-index",1);
    });

    $(document).on('click', '#reset-sort', () => {
      this.sortBy = 'newest';
      $('#ring_sort').val(this.sortBy);
      $('#ring_sort')[0].dispatchEvent(new Event('change'));
      $('#sort-modal').fadeOut();
    });

    $(document).on('click', '.filter-bar.collapsible section > h3', (event) => {
      var section = $(event.target).parent('section');
      if ( section.hasClass('collapsed') ) {
        $.each($('.filter-bar section').not('#' + section.attr('id')), function(k,v) {
          $(v).addClass('collapsed').find('.filters, .fp-text').slideUp();
        });
        section.removeClass('collapsed');
        section.find('.filters, .fp-text').slideDown();
      }
      else {
        section.addClass('collapsed');
        section.find('.filters, .fp-text').slideUp();
      }
    });

    $('#ring_sort').change(function(e) {
      e.preventDefault();
      const $target = $(e.target);
      this.sortBy = $target[0] && $target[0].selectedOptions[0] && $target[0].selectedOptions[0].value ? $target[0].selectedOptions[0].value : null;
      const sortText = $target[0].selectedOptions[0].innerText
      trigger_event('Sorted By', 'Product Listing Page', sortText, 1);

      const newURL = new URL(window.location);
      if (this.sortBy && this.sortBy !== 'newest') {
        newURL.searchParams.set('sort', this.sortBy);
      } else {
        newURL.searchParams.delete('sort');
      }
      window.location.href = newURL.href;
    });

    $(document).on('click', '#clear-filters', (e) => {
      e.preventDefault();
      const url = new URL(window.location);
      this.possible_filters.forEach(param => url.searchParams.delete(param));
      window.location = url;
    });

    $(document).on('click', '.ring-filter-tags a', (e) => {
      e.preventDefault();
      const $target = $(e.target);
      const $filterChipButton = $target.closest('a');
      const indexToRemove = this.getSelectedFilterIndex($filterChipButton.data('key'), $filterChipButton.data('value'));
      this.removeSelectedFilterAt(indexToRemove);
      this.updateDisplayedFilters();
      this.filtersChanged();
    });

    $(document).on('click', '.filter', (e) => {
      e.preventDefault();
      const $target = $(e.target);
      const $filterButton = $target.closest('.filter');

      const existingFilterIndex = this.getSelectedFilterIndex($filterButton.data('key'), $filterButton.data('value'));

      if (existingFilterIndex > -1) {
        this.removeSelectedFilterAt(existingFilterIndex);
      } else {
        trigger_event('Product Listing Page', `Filtered by ${$filterButton.data('keyName')}`, $filterButton.data('valueName'), 1);
        this.selectedFilters.push({
          type: $filterButton.data('key'),
          valueName: $filterButton.data('valueName'),
          value: $filterButton.data('value')
        });
      }

      this.updateDisplayedFilters();
      this.filtersChanged();
    });

    $(window).on('resize', () => {
      const isSizedForMobile = window.innerWidth <= 800;
      $('.filter-bar').toggleClass('collapsible', isSizedForMobile);
      if (!isSizedForMobile) {
        $('.filter-bar section.clp .filters').removeAttr('style')
      }
    });
  }

  injectSortModal() {
    var html = `
        <div class="fullscreen-overlay" id="sort-modal">
          <div class="table modal-wrapper">
            <div class="table-cell">
              <div class="modal">
                <a id="close-sort-modal" class="icon-close"></a>
                <a href="#" class="sc" data-sort="new" id="reset-sort">Reset</a>
                <div class="links">
                  <a href="#" class="sc link${this.sortBy === 'newest' || !this.sortBy ? ' selected': ''}" data-sort="newest">Newest</a>
                  <a href="#" class="sc link${this.sortBy === 'best-sellers' ? ' selected': ''}" data-sort="best-sellers">Best Selling</a>
                  <a href="#" class="sc link${this.sortBy === 'price-high-low' ? ' selected': ''}" data-sort="price-high-low">Price: High to Low</a>
                  <a href="#" class="sc link${this.sortBy === 'price-low-high' ? ' selected': ''}" data-sort="price-low-high">Price: Low to High</a>
                </div>
              </div>
            </div>
          </div>
        </div>`;
    if ( $('#sort-modal').length == 0 ) {
      $('body').append(html);
    }
  }

  getSelectedFilterIndex(type, value) {
    return this.selectedFilters.findIndex(filter => filter.type === type && filter.value === value);
  }

  removeSelectedFilterAt(indexToRemove) {
    this.selectedFilters.splice(indexToRemove, indexToRemove === 0 ? 1 : indexToRemove);
  }

  updateDisplayedFilters() {
    const $filterTagsList = $('.ring-filter-tags');
    const newFilterHTML = this.selectedFilters.map(filter => this.buildFilterChip(filter)).join('');
    $filterTagsList.html(newFilterHTML);
    $filterTagsList.toggleClass('show', this.selectedFilters.length > 0);
    $('#clear-filters').toggle(this.selectedFilters.length > 0);

    const filterSelectors = this.selectedFilters.map(filter => `.filter[data-key="${filter.type}"][data-value="${filter.value}"]`);
    $('.filter').toggleClass('selected', false);
    $(filterSelectors.join(',')).toggleClass('selected', true);
  }

  setSortByFromURL() {
    const url = new URL(window.location);
    this.sortBy = url.searchParams.get('sort');
  }

  buildFilterChip(filter) {
    const $filterTag = $('<div></div>').innerHTML =
    `<li>
      <a href="#" data-key="${filter.type}" data-value="${filter.value}">
        <span class="icon-close"></span>
        <span class="tit">${filter.valueName}</span>
      </a>
    </li>`;

    return $filterTag;
  }

  filtersChanged() {
    const newURL = this.buildNewURL();
    window.location.href = newURL.href;
  }

  buildNewURL() {
    const params = this.selectedFilters.sort((a,b) => a.type.localeCompare(b.type)).reduce((params, filter) => {
      if (!params[filter.type]) {
        params[filter.type] = [];
      }
      params[filter.type].push(filter.value);
      return params;
    }, {});

    const newURL = new URL(window.location);
    this.possible_filters.forEach(filter => newURL.searchParams.delete(filter));
    Object.keys(params).forEach(paramKey => newURL.searchParams.set(paramKey, params[paramKey]));
    return newURL;
  }
}
